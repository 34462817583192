input[type='text'], input[type='search'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'], select, textarea {
    border: .1rem solid color(grey, Gray-200);
    padding: 0 1.5rem;
    color: color(base, DefaultFontColor);
    background-color: color(base, White);
    line-height: 4rem;
    height: 4rem;
    font-size: 1.4rem;
    border-radius: 2rem;
    width: 100%;
}

label {
    display: block;
}

input[type='radio'], input[type='checkbox'] {
    appearance: auto;
}

input[type='search'] {
    @include breakpoint(small only) {
        line-height: 5rem;
        height: 5rem;
    }
}

select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, DefaultFontColor)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m19 9-7 7-7-7'/%3E%3C/svg%3E");
    background-size: 1.6rem;
    background-repeat: no-repeat;
    background-position: right 1rem center;
    padding-right: 1.5rem;
}

button {
    cursor: pointer;
}

.field-error {
    font-size: 1.2rem;
    display: block;
    margin-top: .5rem;
    font-weight: normal;
    color: color(alert, Error);
}

.similarity-message {
    font-size: 1.2rem;
    display: block;
    margin-top: .5rem;
    font-weight: normal;
    color: color(base, DefaultFontColor);
    span {
        cursor: pointer;
        color: color(base, PrimaryBlue);
        text-decoration: underline;
        &:hover {
            color: color(base, PrimaryBlueHover);
        }
    }
}

.field-success {
    font-size: 1.2rem;
    display: block;
    margin-top: .5rem;
    font-weight: normal;
    color: color(alert, Success);
}

.field__container {
    margin: 0 0 2rem;
    label {
        color: color(base, DefaultFontColor);
        font-size: 1.3rem;
        margin: 0 0 1rem;
    }
    .input__group {
        display: flex;
        padding: 1rem 0 0;
        label {
            margin-right: 1rem;
            font-size: 1.4rem;
            cursor: pointer;
        }
        input {
            width: auto;
            margin-right: .5rem;
        }
    }
    &.flex {
        label {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 1rem;
            input {
                margin-right: .5rem;
            }
        }
    }
    input {
        &.failed {
            border-color: color(alert, Error) !important;
        }
        &.valid {
            border-color: color(alert, Success) !important;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23178038' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 12px;
        }
    }
}
