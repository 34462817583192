.swiper-container {
    width: 100%;
    //padding: 0 1rem !important;
}

.swiper-navigation {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.361.6c-.853 0-2.881 1.586-6.084 4.763C3.357 10.2.4 13.25.4 14.513c1.359 2.389 6.44 7.284 15.241 14.686.185 0 .465.064.84.201 1.284-.27 1.919-.793 1.919-1.576 0-.29-3.039-3.138-9.123-8.553a27.832 27.832 0 0 1-4.718-4.959c1.946-2.296 6.186-6.207 12.721-11.734v-.602c0-.666-.642-1.126-1.919-1.376' fill='%23D9D9D9' fill-rule='evenodd'/%3E%3C/svg%3E");
    &.--next {
        transform: scaleX(-1);
    }
    @include breakpoint(medium) {
        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.361.6c-.853 0-2.881 1.586-6.084 4.763C3.357 10.2.4 13.25.4 14.513c1.359 2.389 6.44 7.284 15.241 14.686.185 0 .465.064.84.201 1.284-.27 1.919-.793 1.919-1.576 0-.29-3.039-3.138-9.123-8.553a27.832 27.832 0 0 1-4.718-4.959c1.946-2.296 6.186-6.207 12.721-11.734v-.602c0-.666-.642-1.126-1.919-1.376' fill='#{color(gray, Gray-300)}' fill-rule='evenodd'/%3E%3C/svg%3E");
        }
    }
}

.image__slider-container {
    position: relative;
    .swiper-navigation.--next {
        position: absolute;
        right: 2rem;
        top: 50%;
        z-index: 10;
    }
    .swiper-navigation.--prev {
        position: absolute;
        left: 2rem;
        top: 50%;
        z-index: 10;
    }
}
