#filterPlaceholder {
    .filter__placeholder {
        display: block;
        height: 19rem;
        background: color(base, Placeholder);
        margin: 0 0 2rem;
    }
}

#toolbarPlaceholder {
    ._pagination span {
        display: block;
        height: 3.6rem;
        width: 16rem;
        background: color(base, Placeholder);
    }
}

.category__placeholder {
    .--placeholder {
        display: block;
        height: 40.5rem;
        background: color(base, Placeholder);
        margin: 0 0 2rem;
    }
}

#mobileCategoryPlaceholder {
    ol {
        display: flex;
        gap: 1rem;
        overflow: hidden;

        li {
            a {
                background-color: color(base, SecondaryBlue);
                font-size: 1.4rem;
                color: color(base, White);
                border-radius: 2rem;
                padding: 1rem 1rem;
                white-space: nowrap;
                line-height: 4.4rem;

                @include breakpoint(small only) {
                    height: 3.73rem;
                }
            }
        }
    }
}

#productMedia {
    overflow: hidden;

    .catalog__product_media-gallery_thumbnails {
        display: flex;
        gap: 1rem;

        @include breakpoint(medium) {
            flex-flow: column;
            padding-top: 1rem;
        }

        @include breakpoint(small only) {
            padding: 1.5rem 0;
            height: 10.5rem;
        }

        span {
            display: block;
            background: color(base, Placeholder);
            aspect-ratio: 1;
        }
    }

    .catalog__product_media-gallery_gallery {
        ._holder > span {
            display: block;
            background: color(base, Placeholder);
            aspect-ratio: 1;
        }
    }
}
