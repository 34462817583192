.search__autocomplete {
    position: relative;
    ._loading {
        width: 20px;
        height: 20px;
        right: 5rem;
        left: auto;
        top: 1.3rem;
        bottom: 0;
        background: color(base, White);
        @include breakpoint(small only) {
            width: 20px;
            height: 3rem;
            margin: .1rem 0 0;
            right: 5rem;
            background: color(base, TertiaryBlue);
        }
        ._spinner {
            margin: 0;
            width: 20px;
            height: 20px;
        }
    }
    ._remove {
        @include transition;
        width: 20px;
        height: 5rem;
        position: absolute;
        right: 5rem;
        top: 0;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        @include breakpoint(small only) {
            margin: .1rem 0 0;
        }
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, PrimaryPink)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z' /%3E%3C/svg%3E%0A");
    }
    .search__autocomplete_dropdown {
        position: absolute;
        background: color(base, White);
        border-radius: 0.5rem;
        border: 1px solid color(gray, Gray-200);
        left: -10%;
        right: -10%;
        top: 50px;
        z-index: 50;
        @include breakpoint(small only) {
            left: -.5rem;
            right: -.5rem;
            top: 5rem;
            border: .2rem solid color(base, TertiaryBlue);
        }
        .--container {
            .--header {
                padding: 20px;
                p {
                    text-align: center;
                    width: auto;
                    color: color(gray, Gray-400);
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    justify-content: center;
                    .search {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='#{color(base, PrimaryBlue)}' viewBox='0 0 24 24'%3E%3Cscript xmlns=''/%3E%3Cstyle%3E.spinner_P7sC%7Btransform-origin:center;animation:spinner_svv2 .75s infinite linear%7D@keyframes spinner_svv2%7B100%25%7Btransform:rotate(360deg)%7D%7D%3C/style%3E%3Cpath d='M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z' class='spinner_P7sC'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");
                        background-position: right center;
                        background-repeat: no-repeat;
                        background-size: 2rem;
                        padding-right: 3rem;
                    }
                }
            }
            .--content {
                @include breakpoint(small only) {
                    height: 100%;
                    overflow-y: scroll;
                    max-height: calc(100vh - 300px);
                    padding: 0 0 30px;
                }
            }
        }
        .--categories, .--keywords {
            padding: 10px 20px;
            h4 {
                font-size: 14px;
                line-height: 2.4rem;
                color: color(base, DefaultFontColor);
                font-weight: 500;
            }
        }
        .--products {
            padding: 10px 20px;
            ul {
                li a {
                    gap: 1rem;
                    align-items: center;
                }
            }
        }
        ul {
            li {
                a {
                    display: flex;
                    padding: 5px 10px;
                    border-bottom: 1px solid color(gray, Gray-100);
                    color: color(base, DefaultFontColor);
                    @include breakpoint(small only) {
                        background: color(base, White);
                        border-bottom: .2rem solid color(base, TertiaryBlue);
                    }
                    &:hover {
                        text-decoration: none;
                        background: color(base, TertiaryBlue);
                    }
                    .image__holder {
                        display: block;
                        aspect-ratio: 1;
                        width: 60px;
                        height: 60px;
                        overflow: hidden;
                        object-fit: cover;
                    }
                    .product__info {
                        flex: 1;
                        display: block;
                        overflow: hidden;
                    }
                    ._title {
                        flex: 1;
                        padding: 9px 0;
                        font-size: 14px;
                        line-height: 20px;
                        color: color(base, Black);
                        font-weight: normal;
                        @include breakpoint(small only) {
                            font-size: 1.4rem;
                            line-height: 16px;
                            margin-right: 10px;
                        }
                    }
                    .price__holder {
                        padding: 5px 0;
                        margin-left: auto;
                        .--old {
                            font-size: 12px;
                            text-decoration: line-through;
                            text-align: right;
                            color: color(gray, Gray-200);
                            line-height: 1.4rem;
                            @include breakpoint(small only) {
                                font-size: 10px;
                            }
                        }
                        .--special, .price {
                            text-align: right;
                            display: inline-block;
                            font-size: 14px;
                            line-height: 30px;
                            font-weight: 500;
                            color: color(base, PrimaryPink);
                            @include breakpoint(small only) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
                &.more__results a {
                    border: none;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 20px;
                    @include breakpoint(small only) {
                        font-weight: 500;
                    }
                }
            }
        }
        .--footer {
            padding: 0 20px 10px;
            @include breakpoint(small only) {
                padding: 5px 20px 10px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: white;
                text-align: center;
                width: 100%;
                border-top: 1px solid color(gray, Gray-100);
            }
            a {
                display: block;
                text-align: center;
                font-size: 13px;
                color: color(gray, Gray-400);
                &:hover {
                    color: color(base, PrimaryBlue);
                }
            }
        }
        .no__results {
            display: block;
            text-align: center;
            font-size: 12px;
            font-weight: normal;
            line-height: 30px;
        }
    }
}
