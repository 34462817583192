.wishlist__banner{
    background-color: color(base, TertiaryBlue);
    padding:2rem;
    border-radius: .5rem;
    position: fixed;
    text-align: center;
    bottom:3rem;
    h2{
        font-size:2.4rem;
        font-weight: 600;
    }
    left:3rem;
    z-index: 99999994;
    width: calc(100vw - 6rem);
    .close-button {
        width: 2rem;
        height: 2rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(gray, Gray-400)}'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
        right: 1.6rem;
        top: 1.6rem;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        position:absolute;
        cursor: pointer;
    }
}
