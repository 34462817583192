#tweakwise-guided-selling {
    margin: 4rem 0 8rem;
    .twn-results {
        .twn-results__head {
            .twn-results__title {
                color: color(base, PrimaryBlue);
                font-size: 26px;
                font-weight: 500;
                line-height: 28px;
                margin: 2rem 0;
            }
            .twn-results__count {
                font-size: 1.4rem;
            }
        }
        .twn-results__body {
            .twn-product-tile {
                padding: 1rem;
                border-radius: .5rem;
                border: .1rem solid transparent;
                position: relative;
                &:hover {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                }
                .twn-product-tile__details {
                    .twn-product-tile__title {
                        a {
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                    .twn-product-tile__pricing {
                        .twn-product-tile__price {
                            &:not(.is-original) {
                                color: color(base, PrimaryPink);
                                display: inline-block;
                                font-size: 2.5rem;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}
