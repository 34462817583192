.menu {
    overflow-x: hidden;
    height: calc(100vh - 4.7rem);
    > ul {
        padding: .5rem 0 0;
        margin: .5rem 0 0;
        button {
            margin: 1rem 0 0;
            font-size: 14px;
            padding: 0 1.5rem 0 3.5rem;
            line-height: 3.2rem;
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m15 19-7-7 7-7'/%3E%3C/svg%3E");
            background-position: left 1rem center;
            background-repeat: no-repeat;
            background-size: 1.8rem;
        }
        a {
            border: none;
            font-size: 14px;
            line-height: 3.2rem;
            padding: 0 1.5rem;
            font-weight: normal;
            text-transform: capitalize;
        }
        li.--active a {
            font-weight: 700;
            pointer-events: none;
            color: color(base, DefaultFontColor);
        }
        li.is-drilldown-submenu-parent {
            > a {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m9 5 7 7-7 7'/%3E%3C/svg%3E");
                background-position: right 1rem center;
                background-repeat: no-repeat;
                background-size: 1.8rem;
                display:flex;
                align-items: center;
            }
        }
        li {
            > a {
                display:flex;
                align-items: center;
                img{
                    width: 5rem;
                    margin-right: 1.6rem;
                }
            }
        }
        &.cms-navigation {
            a {
                font-size: 1.2rem;
            }
        }
        &._sub-navigation {
            border-top: .1rem dashed color(gray, Gray-200);
            padding: 0;
            margin: 0;
        }
    }
    #menu__overview {
        a {
            font-weight: 600;
            text-transform: initial;
            color: color(base, PrimaryPink);
        }
    }
}

.main__navigation {
    background: color(base, TertiaryBlue);
    nav {
        position: relative;
        > ul {
            display: flex;
            margin: 0 -2.5rem;
            flex-wrap: wrap;
            > li {
                cursor: pointer;
                > a {
                    position: relative;
                    display: block;
                    padding: 0 2.5rem;
                    font-size: 1.6rem;
                    line-height: 4.8rem;
                    color: color(base, PrimaryBlue);
                    font-weight: 500;
                    text-transform: capitalize;
                    &::after {
                        content: '';
                        width: 100%;
                        display: block;
                        position: relative;
                        border-bottom: .2rem solid transparent;
                    }
                }
                &:hover > a::after {
                    border-color: color(base, PrimaryBlue);
                }
                &.navigation-group {
                    > ul {
                        display: none;
                        position: absolute;
                        inset: auto 0 auto 0;
                        background: color(base, White);
                        flex-flow: row wrap;
                        padding: 2rem 2rem 3rem;
                        border-radius: 0 0 .5rem .5rem;
                        box-shadow: 0 .1rem .2rem rgb(0 0 0 / 40%);
                        > li {
                            width: 16.666667%;
                            padding-right: 2rem;
                            > a {
                                font-weight: 500;
                                font-size: 1.5rem;
                                margin: 0 0 .5rem;
                                display: inline-block;
                            }
                            > ul {
                                margin: 0 0 1rem;
                                > li {
                                    a {
                                        color: color(gray, Gray-300);
                                        display: inline-block;
                                        line-height: 2.6rem;
                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .navigation-element-more {
                        font-weight: 500;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-300)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 5l7 7-7 7' /%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: 1.2rem;
                        background-position: right bottom 0.6rem;
                        padding-right: 1.5rem;
                    }
                }
            }
        }
    }
}
