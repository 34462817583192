@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin transition {
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@mixin transition-all() {
    transition: all 0.3s ease;
}

@mixin head-font {
    font-family: 'Ubuntu', sans-serif;
}

@mixin header-icons {
    background-repeat: no-repeat;
    display: block;
    font-size: 1.2rem;
    line-height: 1rem;
    padding: 3.2rem 0 1rem;
    @include breakpoint(small only) {
        font-size: 1rem;
        padding: 3rem 0 0;
    }
}

@mixin default-button {
    @include transition;
    height: 4rem;
    line-height: 4rem;
    justify-content: center;
    display: inline-flex;
    color: color(base, White);
    background-color: color(base, PrimaryPink);
    font-weight: 500;
    font-size: 1.4rem;
    border-radius: 2rem;
    padding: 0 2rem;
    cursor: pointer;
    @include breakpoint(medium) {
        &:visited, &:focus {
            color: color(base, White);
        }
        &:hover {
            background-color: color(base, PrimaryPinkHover);
            color: color(base, White);
        }
    }

    &.--secondary {
        background-color: color(base, PrimaryBlue);
        @include breakpoint(medium) {
            &:visited, &:focus {
                color: color(base, White);
            }
            &:hover {
                background-color: color(base, PrimaryBlueHover);
                color: color(base, White);
            }
        }
    }
    &.--tertiary {
        background-color: color(base, White);
        border: 1px solid color(base, PrimaryPink);
        color: color(base, PrimaryPink);
        &:hover {
            background-color: color(base, PrimaryPink);
            color: color(base, White);
        }
    }
    &.--cta {
        background-color: color(base, PrimaryOrange);
        color: color(base, White);
        &:not(:disabled):hover {
            background-color: color(base, PrimaryOrangeHover);
            color: color(base, White);
        }
    }
    &:disabled {
        cursor: not-allowed;
        background: color(gray, Gray-200);
    }
}

@mixin messages($type) {
    @if ($type == "warning") {
        background-color: #fcf8e3;
        border-color: #faf2cc;
        color: #8a6d3b;
        //color: color(alert, Warning);
        a {
            color: #8a6d3b;
            //color: color(alert, Warning);
            text-decoration: underline;
        }
        strong {
            color: #8a6d3b;
            font-weight: 600;
        }
    } @else if ($type == "error") {
        background-color: scale-color(color(alert, Error), $lightness: 90%);
        border: 1px solid scale-color(color(alert, Error), $lightness: 80%);
        color: color(alert, Error);
        a {
            color: color(alert, Error);
            text-decoration: underline;
        }
    } @else if ($type == "success") {
        background-color: #dff0d8;
        border-color: #d0e9c6;
        color: #3c763d;
        a {
            color: #3c763d;
            text-decoration: underline;
        }
    }
}

.--button, ._button {
    @include default-button;
}

@mixin promo-container($height: 4rem, $padding: 1rem, $font-size: 1.3rem, $font-size-heading: 1.3rem) {
    .promo_time--container {
        border: .1rem solid color(base, PrimaryBlue);
        border-radius: 1rem;
        line-height: inherit;
        display: inline-flex;
        overflow: hidden;

        span:first-of-type {
            font-size: $font-size-heading;
            font-weight: 600;
            background: color(base, PrimaryPink);
            color: color(base, White);
            height: auto;
            padding: 0 $padding;
        }

        span:last-of-type {
            font-size: $font-size;
            font-weight: 600;
            color: color(base, PrimaryPink);
            padding: 0 $padding;
        }
    }
}

@mixin loading {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    left: -5px;
    right: -5px;
    top: 0;
    bottom: 0;
    z-index: 7;
    ._spinner {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='%23000' d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        width: 40px;
        height: 40px;
        background-size: contain;
        display: block;
        margin: 10% auto;
        mask-size: contain;
        z-index: 8;
    }
}

._loading {
    @include loading;
}


@mixin delivery{
    span {
        color: color(alert, Success);
        line-height: 2.4rem;
        font-size: 1.4rem;
        font-weight: 500;
        margin: 1rem 0 1rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(alert, Success)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5 13l4 4L19 7' /%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 2rem;
        padding-left: 2.5rem;
        @include breakpoint(small only) {
            font-size: 1.3rem;
            padding-left: 2.2rem;
        }
    }
    &.out-of-stock {
        span {
            color: color(gray, Gray-300);
            line-height: 2.4rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-300)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 2rem;
            padding-left: 2.5rem;
            @include breakpoint(small only) {
                font-size: 1.3rem;
                padding-left: 2.2rem;
            }
        }
    }
}

@mixin hover() {
    @include breakpoint(medium) {
        &:not(:disabled):hover {
            @content;
        }
    }
}

@mixin absolute-button() {
    > button, .slider__control {
        @include transition;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        z-index: 3;
        background-size: 1.6rem;
        background-repeat: no-repeat;
        background-position: center;
        border: .1rem solid transparent;

        &.blaze-prev, &._prev {
            left: .5rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5 8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
            @include breakpoint(small only) {
                left: -1rem;
            }
            @include breakpoint(medium up) {
                left: -2.5rem;
            }
        }

        &.blaze-next, &._next {
            right: .5rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m8.25 4.5 7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
            @include breakpoint(small only) {
                right: -1rem;
            }
            @include breakpoint(medium up) {
                right: -2.5rem;
            }
        }

        @include hover {
            opacity: 1;
            border-color: color(base, SecondaryBlue);
            box-shadow: 0 0 20px rgba(115, 184, 223, 0.3);
        }

        &.--inverted {
            &.blaze-prev {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5 8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
                @include breakpoint(medium) {
                }

                &.blaze-next {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m8.25 4.5 7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                    @include breakpoint(medium) {
                    }
                }
            }
        }
    }
    &.start, &.static {
        .blaze-prev {
            display: none;
        }
    }

    &.end, &.static {
        .blaze-next {
            display: none;
        }
    }
}
