.catalog__product_media-gallery {
    //&:not(.--placeholder) {
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    right: 0;
    //}

    .catalog__product_media-gallery_thumbnails {
        .slider__control {
            margin: 0 auto;
            cursor: pointer;
            &.--prev {
                transform: rotate(90deg);
            }
            &.--next {
                transform: rotate(-90deg);
            }
            @include breakpoint(small only) {
                display: none;
            }
        }
        .thumb__holder, .video__thumbnail {
            position: relative;
            display: block;
            border: .1rem solid color(base, SecondaryBlue);
            background: color(base, White);
            cursor: pointer;
            padding: .6rem;
            border-radius: 1rem;
            width: 75px;
            @include breakpoint(small only) {
                margin: 0;
            }
            img {
                aspect-ratio: 1;
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
            &.--active, &:hover {
                border-color: color(base, PrimaryBlue);
                border-width: .2rem;
                padding: .5rem;
            }
            ._zoom {
                display: block;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Casba' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Casba_Product_Desktop_V2' transform='translate(-247.000000, -664.000000)'%3E%3Cg id='Main' transform='translate(178.000000, 1.000000)'%3E%3Cg id='Top-left' transform='translate(51.000000, 0.000000)'%3E%3Cg id='Zoom' transform='translate(19.780937, 664.824818)'%3E%3Cpath d='M18.9099998,9.45546875 C18.9099998,14.6771701 14.6780916,18.9109375 9.45499992,18.9109375 C4.23355744,18.9109375 0,14.6771701 0,9.45546875 C0,4.23211806 4.23355744,0 9.45499992,0 C14.6780916,0 18.9099998,4.23211806 18.9099998,9.45546875 Z' id='Stroke-1' stroke='%23303030' stroke-width='2'%3E%3C/path%3E%3Cline x1='13.995625' y1='17.4740625' x2='22.6565625' y2='27.3001823' id='Stroke-3' stroke='%23303030' stroke-width='2'%3E%3C/line%3E%3Cline x1='9.7190625' y1='5.55018229' x2='9.7190625' y2='13.5501823' id='Line-6' stroke='%23989898' stroke-linecap='square'%3E%3C/line%3E%3Cline x1='5.2190625' y1='9.67518229' x2='14.2190625' y2='9.67518229' id='Line-6' stroke='%23989898' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                height: auto;
                &:after {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }
            }
        }

        .video__thumbnail {
            width: 8rem;
            height: 8rem;
            display: block;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='50px' height='50px' viewBox='0 0 50 50' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group'%3E%3Ccircle id='Oval' stroke='#{color(base, PrimaryBlueHover)}' stroke-width='2' cx='25' cy='25' r='24'%3E%3C/circle%3E%3Cpolygon id='Triangle' fill='#{color(base, PrimaryBlueHover)}' transform='translate(27.500000, 24.500000) rotate(-270.000000) translate(-27.500000, -24.500000) ' points='27.5 15 39 34 16 34'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            @include breakpoint(small only) {
                width: 75px;
                height: 7.75rem;
                background-size: 3rem;
            }
        }
    }
    .catalog__product_media-gallery_gallery {
        position: relative;
        max-width: 600px;
        margin: 0 auto;
        .--label {
            background: color(base, PrimaryPink);
            color: color(base, White);
            position: absolute;
            top: 2rem;
            left: 2rem;
            font-size: 1.2rem;
            font-weight: 700;
            padding: 0 1rem;
            line-height: 3.2rem;
            border-radius: 1.6rem;
            z-index: 5;
            @include breakpoint(small only) {
                padding: 0 .5rem;
                line-height: 2.4rem;
                left: 0;
            }
        }
        ._zoom {
            position: absolute;
            cursor: pointer;
            display: block;
            font-size: 1.2rem;
            font-style: italic;
            color: color(gray, Gray-300);
            height: 2.4rem;
            line-height: 2.4rem;
            background-size: 1.4rem;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='%23989898'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='10.5' y1='6.375' x2='10.5' y2='14.375' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            z-index: 3;
            bottom: 2rem;
            right: 2rem;
            padding-left: 1.8rem;
        }
        .blaze-slider {
            > button {
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: 1.6rem;
                border: .1rem solid transparent;
                height: 3.2rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: all .3s ease;
                width: 3.2rem;
                z-index: 5;
                &.blaze-prev {
                    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nMTknIGhlaWdodD0nMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTE1LjM2MS42Yy0uODUzIDAtMi44ODEgMS41ODYtNi4wODQgNC43NjNDMy4zNTcgMTAuMi40IDEzLjI1LjQgMTQuNTEzYzEuMzU5IDIuMzg5IDYuNDQgNy4yODQgMTUuMjQxIDE0LjY4Ni4xODUgMCAuNDY1LjA2NC44NC4yMDEgMS4yODQtLjI3IDEuOTE5LS43OTMgMS45MTktMS41NzYgMC0uMjktMy4wMzktMy4xMzgtOS4xMjMtOC41NTNhMjcuODMyIDI3LjgzMiAwIDAgMS00LjcxOC00Ljk1OWMxLjk0Ni0yLjI5NiA2LjE4Ni02LjIwNyAxMi43MjEtMTEuNzM0di0uNjAyYzAtLjY2Ni0uNjQyLTEuMTI2LTEuOTE5LTEuMzc2JyBmaWxsPScjRDlEOUQ5JyBmaWxsLXJ1bGU9J2V2ZW5vZGQnLz48L3N2Zz4=);
                    left: -2rem;
                }
                &.blaze-next {
                    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nMTknIGhlaWdodD0nMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTE1LjM2MS42Yy0uODUzIDAtMi44ODEgMS41ODYtNi4wODQgNC43NjNDMy4zNTcgMTAuMi40IDEzLjI1LjQgMTQuNTEzYzEuMzU5IDIuMzg5IDYuNDQgNy4yODQgMTUuMjQxIDE0LjY4Ni4xODUgMCAuNDY1LjA2NC44NC4yMDEgMS4yODQtLjI3IDEuOTE5LS43OTMgMS45MTktMS41NzYgMC0uMjktMy4wMzktMy4xMzgtOS4xMjMtOC41NTNhMjcuODMyIDI3LjgzMiAwIDAgMS00LjcxOC00Ljk1OWMxLjk0Ni0yLjI5NiA2LjE4Ni02LjIwNyAxMi43MjEtMTEuNzM0di0uNjAyYzAtLjY2Ni0uNjQyLTEuMTI2LTEuOTE5LTEuMzc2JyBmaWxsPScjRDlEOUQ5JyBmaWxsLXJ1bGU9J2V2ZW5vZGQnLz48L3N2Zz4=);
                    transform: translateY(-50%) scaleX(-1);
                    right: -2rem;
                }
            }
        }
        .blaze-container {
            height: 100%;
            .responsive__video {
                padding: 1rem 0 0;
                display: flex;
                align-items: center;
                @include breakpoint(small only) {
                    margin: 1rem 0 0;
                    width: 100%;
                    position: relative;
                }
                iframe {
                    @include breakpoint(small only) {
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }

            ._holder {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                position: relative;
                picture {
                    img {
                        max-width: 100%;
                        object-fit: cover;
                        width: auto;
                        height: 100%;
                        cursor: zoom-in;
                        max-height: 50rem;
                        @include breakpoint(small only) {
                            max-height: calc(100vw - 4rem);
                            max-width: calc(100vw - 4rem);
                        }
                    }
                }
            }
        }
        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .slider__controls {
            .swiper-navigation {
                position: absolute;
                top: 50%;
                cursor: pointer;
                &.--prev {
                    transform: translateY(-50%);
                    left: 0;

                }
                &.--next {
                    transform: translateY(-50%) scaleX(-1);
                    right: 0;
                }
            }
        }
    }
    .catalog__product_media-gallery_fullscreen {
        position: fixed;
        z-index: 8000;
        background: color(base, White);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        .catalog__product-title {
            @include transition;
            position: absolute;
            display: inline;
            top: 2rem;
            left: 2rem;
            font-weight: 500;
            z-index: 9500;
            margin-right: 6rem;
            line-height: 2.6rem;
        }
        ._zoom {
            position: absolute;
            @include transition;
            cursor: pointer;
            display: block;
            width: 35px;
            height: 42px;
            background-size: 2rem;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='%23989898'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='10.5' y1='6.375' x2='10.5' y2='14.375' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            z-index: 3;
            bottom: 10%;
            right: 30%;
            @include breakpoint(small only) {
                bottom: 100px;
                right: 20px;
            }
            &:hover {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='#{color(base, PrimaryBlue)}'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='10.5' y1='6.375' x2='10.5' y2='14.375' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
            &._zoomed {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='%23989898'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                &:hover {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='#{color(base, PrimaryBlue)}'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }
        ._close {
            @include transition;
            z-index: 77;
            cursor: pointer;
            position: absolute;
            width: 35px;
            height: 35px;
            top: 20px;
            right: 20px;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EX%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='02-Reclaimed_Categorie_-Filter_MOBILE-V4' transform='translate(-15.000000, -20.000000)'%3E%3Cg id='Mid' transform='translate(-2.000000, -2.000000)' fill='#{color(base, Black)}' font-family='ProximaNova-Regular, Proxima Nova' font-size='14' font-weight='normal' line-spacing='30'%3E%3Ctext id='Nieuw-Vintage-Prijs'%3E%3Ctspan x='17' y='13.0546875'%3E%3C/tspan%3E%3Ctspan x='17' y='63.0546875'%3ENieuw%3C/tspan%3E%3Ctspan x='17' y='113.054688'%3EVintage%3C/tspan%3E%3Ctspan x='17' y='163.054688'%3EPrijs%3C/tspan%3E%3Ctspan x='17' y='213.054688'%3ERingmaat%3C/tspan%3E%3Ctspan x='17' y='263.054688'%3ESlijpvorm%3C/tspan%3E%3C/text%3E%3C/g%3E%3Cg id='Top' transform='translate(-28.000000, 0.000000)' stroke='#{color(base, Black)}' stroke-width='2'%3E%3Cg id='X' transform='translate(44.000000, 21.000000)'%3E%3Cline x1='5.35924893e-14' y1='-1.33981223e-14' x2='14' y2='14' id='Line'%3E%3C/line%3E%3Cpath d='M0,14 L14,0 L0,14 Z' id='Line'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 2rem;
            &:hover {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EX%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='02-Reclaimed_Categorie_-Filter_MOBILE-V4' transform='translate(-15.000000, -20.000000)'%3E%3Cg id='Mid' transform='translate(-2.000000, -2.000000)' fill='#{color(base, PrimaryPink)}' font-family='ProximaNova-Regular, Proxima Nova' font-size='14' font-weight='normal' line-spacing='30'%3E%3Ctext id='Nieuw-Vintage-Prijs'%3E%3Ctspan x='17' y='13.0546875'%3E%3C/tspan%3E%3Ctspan x='17' y='63.0546875'%3ENieuw%3C/tspan%3E%3Ctspan x='17' y='113.054688'%3EVintage%3C/tspan%3E%3Ctspan x='17' y='163.054688'%3EPrijs%3C/tspan%3E%3Ctspan x='17' y='213.054688'%3ERingmaat%3C/tspan%3E%3Ctspan x='17' y='263.054688'%3ESlijpvorm%3C/tspan%3E%3C/text%3E%3C/g%3E%3Cg id='Top' transform='translate(-28.000000, 0.000000)' stroke='#{color(base, PrimaryPink)}' stroke-width='2'%3E%3Cg id='X' transform='translate(44.000000, 21.000000)'%3E%3Cline x1='5.35924893e-14' y1='-1.33981223e-14' x2='14' y2='14' id='Line'%3E%3C/line%3E%3Cpath d='M0,14 L14,0 L0,14 Z' id='Line'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
        }
        .catalog__product_media-gallery_gallery {
            width: 100%;
            height: 80%;
            @include breakpoint(small only) {
                height: 80%;
                .swiper-container {
                    height: 100%;
                }
            }
            ._holder {
                background: transparent;
                @include breakpoint(small only) {
                    max-height: 60vh;
                    picture {
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }
                }
                &._has-thumbs {
                    img {
                        max-height: calc(100vh - 140px);
                        width: auto;
                    }
                    //.video__holder {
                    //    max-height: calc(100vh - 140px);
                    //}
                }
                .video__holder {
                    margin: 0 auto;
                    padding: 40px;
                    max-width: 100vh;
                    @include breakpoint(small only) {
                        max-width: 90vw;
                    }
                    iframe {
                        @include breakpoint(small only) {
                            width: 100%;
                            height: auto;
                            display: flex;
                        }
                    }
                }
            }
            .slider__controls {
                .--prev {
                    left: 2rem;
                    z-index:9000;
                    @include breakpoint(small only) {
                        left: 1rem;
                    }
                }
                .--next {
                    right: 2rem;
                    z-index: 9000;
                    @include breakpoint(small only) {
                        right: 1rem;
                    }
                }
            }
        }
        .catalog__product_media-gallery_thumbnails {
            position: absolute;
            margin: 0 auto;
            bottom: 1rem;
            left: 50%;
            transform: translateX(-50%);
            @include breakpoint(small only) {
                left: 2rem;
                right: 0;
                transform: initial;
                overflow: hidden;
                bottom: 8rem;
            }
            .swiper-slide {
                width: 20%;
                @include breakpoint(large) {
                    width: 70px;
                }
            }
            .thumb__holder {
                height: 6.5rem;
                width: 6.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .play__video {
                height: 5.5rem;
                width: 5.5rem;
                background-size: 4rem;
                display: block;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='50px' height='50px' viewBox='0 0 50 50' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group'%3E%3Ccircle id='Oval' stroke='#{color(base, PrimaryBlueHover)}' stroke-width='2' cx='25' cy='25' r='24'%3E%3C/circle%3E%3Cpolygon id='Triangle' fill='#{color(base, PrimaryBlueHover)}' transform='translate(27.500000, 24.500000) rotate(-270.000000) translate(-27.500000, -24.500000) ' points='27.5 15 39 34 16 34'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                @include breakpoint(small only) {
                    width: 6.5rem;
                    height: 6.5rem;
                    background-size: 4rem;
                }
            }
        }
    }
}

.product__media-holder {
    position: relative;
}

.catalog__product_media-gallery_thumbnails, #thumbnailGallery {
    .blaze-container {
        overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
        }
        .track-container {
            display: grid;
            grid-auto-flow: column;
            align-items: start;
            .blaze-track {
                --slide-width: auto !important;
                touch-action: pan-x;
            }
        }
    }
}