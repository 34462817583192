.contact-form {
    margin: 0 0 8rem;
    h1, h2 {
        color: color(base, Black);
        font-size: 2.4rem;
        font-weight: 600;
        margin: 0 0 1.5rem;
    }
    label {
        font-weight: 500;
    }
    textarea {
        height: 100%;
        border-radius: 1rem;
    }
    input[type='text'], input[type='phone'], input[type='email'], input[type='tel'] {
        &:focus {
            border: 1px solid color(alert, Error);
            outline: none;
        }
        &:valid:not(:placeholder-shown) {
            border: 1px solid color(alert, Success);
            outline: none;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23178038' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 12px;
        }
    }
    textarea {
        &:focus {
            border: 1px solid color(alert, Error);
            outline: none;
        }
        &:valid:not(:placeholder-shown) {
            border: 1px solid color(alert, Success);
            outline: none;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23178038' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right 10px top 15px;
            background-size: 12px;
        }
    }
    .field__container.required label:after {
        content: '*';
        color: color(alert, Error);
        font-size: 1.2rem;
        margin-left: 0.2rem;
    }
    .contact__block {
        ol {
            list-style-type: auto;
            list-style-position: inside;
            font-size: 1.4rem;
            margin: 2rem 0;
        }
        @include breakpoint(small only) {
            margin: 0 0 2rem;
        }
        div {
            margin: 2.4rem 0;
            > p {
                position: relative;
                margin-left: 3rem;
                &:before {
                    content: '';
                    display: block;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCA0NCA0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImhlYXJ0Ij4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTM4LjY2NjcgMTAuMzE1MUMzNy4xNzc4IDcuMjU5NSAzMi44ODg5IDQuNzU5NSAyNy45IDYuMjE1MDZDMjUuNTE2MSA2LjkwMzY1IDIzLjQzNjEgOC4zODAzNyAyMiAxMC40MDM5QzIwLjU2MzkgOC4zODAzNyAxOC40ODM5IDYuOTAzNjUgMTYuMSA2LjIxNTA2QzExLjEgNC43ODE3MiA2LjgyMjIyIDcuMjU5NSA1LjMzMzMzIDEwLjMxNTFDMy4yNDQ0NCAxNC41OTI4IDQuMTExMTEgMTkuNDAzOSA3LjkxMTExIDI0LjYxNTFDMTAuODg4OSAyOC42OTI4IDE1LjE0NDQgMzIuODI2MiAyMS4zMjIyIDM3LjYyNjJDMjEuNTE3NSAzNy43Nzg0IDIxLjc1OCAzNy44NjExIDIyLjAwNTYgMzcuODYxMUMyMi4yNTMyIDM3Ljg2MTEgMjIuNDkzNyAzNy43Nzg0IDIyLjY4ODkgMzcuNjI2MkMyOC44NTU2IDMyLjgzNzMgMzMuMTIyMiAyOC43MzczIDM2LjEgMjQuNjE1MUMzOS44ODg5IDE5LjQwMzkgNDAuNzU1NiAxNC41OTI4IDM4LjY2NjcgMTAuMzE1MVYxMC4zMTUxWiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8L3N2Zz4K);
                    background-repeat: no-repeat;
                    background-color: color(base, PrimaryPink);
                    background-size: 1.4rem;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    vertical-align: middle;
                    background-position: center;
                    margin-top: 0.2rem;
                    position: absolute;
                    left: -3rem;
                }
            }
        }
    }
    .field__actions {
        @include breakpoint(small only) {
            margin: 0 0 6rem;
        }
    }
    .contact-form_box {
        background: color(base, TertiaryBlue);
        padding: 2rem;
        border-radius: 0.5rem;
    }
}
