.product__reviews-container {
    margin: 6rem 0 0;
    position: relative;
    @include breakpoint(small only) {
        margin: 6rem 0 4rem;
    }
    h2 {
        font-size: 2.4rem;
        @include breakpoint(small only) {
            font-size: 2rem;
        }
        line-height: 3.6rem;
        margin: 0 0 1rem;
        color: color(base, DefaultFontColor);
    }
    ._header {
        margin: 1rem 0 3rem;
        .rating {
            .rating__count {
                margin: 0 2rem 0 0;
                strong {
                    display: block;
                    font-size: 2.6rem;
                    line-height: 3rem;
                    margin: 0 0 0.5rem;
                }
            }
            .rating__block {
                width: 13.5rem;
                height: 2.6rem;
                background-size: cover;
                .rating__block_fill {
                    height: 2.6rem;
                    background-size: cover;
                }
            }
            ._ratings {
                display: block;
                font-size: 1.4rem;
                color: color(base, DefaultFontColor);
                margin: 1rem 0 0;
            }
        }
    }
    .product__reviews {
        position: relative;
        .review__create {
            position: absolute;
            top: -8rem;
            right: 0;
        }
        ul {
            li {
                border-top: .1rem solid color(base, TertiaryBlue);
                padding: 2rem 0 0;
                .--review {
                    background: color(base, TertiaryBlue);
                    padding: 1.5rem 2rem;
                    margin: 0 0 2rem;
                    width: 100%;
                    border-radius: 2rem;
                    .--header {
                        display: flex;
                        align-items: start;
                        gap: 1rem;
                        margin: 0 0 1.5rem;
                        h3 {
                            font-weight: 600;
                            font-size: 1.4rem;
                            max-width: 68%;
                        }
                    }
                    .review__author {
                        display: block;
                        margin: 1.5rem 0 0;
                        color: color(gray, Gray-300);
                        font-size: 1.3rem;
                    }
                }
                .--comment {
                    margin: 0 0 3rem 2rem;
                    padding-left: 1.5rem;
                    width: fit-content;
                    font-size: 1.4rem;
                    font-style: italic;
                    border-left: .2rem solid color(gray, Gray-200);
                    strong {
                        display: block;
                        margin: 0 0 .5rem;
                    }
                }
                &._actions {
                    display: flex;
                    justify-content: center;
                    button {
                        background-size: 1.4rem;
                        background-position: right 2rem center;
                        background-repeat: no-repeat;
                        padding: 0 4rem 0 3rem;
                        position: relative;
                        &:hover {
                            background-color: color(base, White);
                            color: color(base, PrimaryPink);
                        }
                        &:after {
                            content: '';
                            display: block;
                            width: 1.4rem;
                            height: 1.4rem;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, PrimaryPink)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
                            background-size: 1.4rem;
                            background-position: center;
                            background-repeat: no-repeat;
                            position: absolute;
                            top: 50%;
                            right: 1.5rem;
                            transform: translateY(-50%);
                        }
                        &.--active {
                            &:after {
                                transform: translateY(-50%) rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
}

#reviews-offCanvas {
    section {
        padding: 10px;
        max-height: calc(100vh - 50px);
        overflow: scroll;
        ul > li:first-child {
            border-top: none;
        }
        h3 {
            max-width: 63%;
        }
    }
}
