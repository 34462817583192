#solar-app .blog__homepage {
    .anchor-point {
        position: relative;
        top: -400px;
    }

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    h2 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    h3 {
        a {
            font-size: 16px;
        }

        margin-bottom: 20px;
    }
}

#solar-app .back_button {
    background: color(base, PrimaryBlue);
    color: color(base, White);
    text-decoration: none;
    padding: 10px 20px 10px 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
    background-size: 14px;
    background-position: left 8px center;
    background-repeat: no-repeat;
    border-radius: 70px;
    margin-bottom: 20px;
    display: inline-block;
    @include breakpoint(small only) {
        padding: 5px 15px 5px 25px;
        background-size: 10px;
    }

    &:hover {
        background: color(base, PrimaryBlueHover);
    }
}
