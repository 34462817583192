$sl-font-family: 'Beausite Classic', sans-serif;
$sl-overlay-background: color(base, Black);

.sl-wrapper {
    .sl-close {
        @include transition;
        display: none;
        background-color: color(base, White);
        border-radius: 50%;
        background-size: 3rem;
        background-repeat: no-repeat;
        background-position: center;
        border: .1rem solid transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");

        @include hover {
            opacity: 1;
            border-color: color(base, SecondaryGreen);
            box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);
        }
    }

    .sl-navigation {
        button {
            @include transition;
            background-color: color(base, White);
            border-radius: 50%;
            background-size: 3rem;
            background-repeat: no-repeat;
            background-position: center;
            border: .1rem solid transparent;

            @include breakpoint(small only) {
                width: 4.4rem !important;
            }

            &.sl-prev {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75' /%3E%3C/svg%3E%0A");
            }

            &.sl-next {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75' /%3E%3C/svg%3E%0A");
            }

            @include hover {
                opacity: 1;
                border-color: color(base, SecondaryGreen);
                box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);
            }
        }
    }
}
