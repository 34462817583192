.product-blog-container {
    margin: 6rem 0;
    background-color: color(base, TertiaryBlue);
    padding: 1.5rem;
    max-width: 100%;
    border-radius: 2rem;
    .blog {
        display: flex;
        justify-content: space-between;
        margin: 1.5rem 0 1rem;

        a {
            text-decoration: underline;
            align-self: center;
            flex-basis: 90%;
            color: color(base, Black);
        }
    }

    .info {
        border-radius: 100%;
        background: color(base, PrimaryBlue);
        width: 30px;
        height: 30px;
        color: white;
        font-size: 22px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-self: center;
        margin-right: 1.5rem;
        margin-top:1rem;

        span {
            align-self: center;
        }
    }

    h3 {
        font-size: 2.4rem;
        margin: 0 0 1rem;
        @include breakpoint(small only) {
            font-size: 2rem;
        }
    }
}
