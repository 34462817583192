.brand__page {
    margin: 0 0 4rem;
    .brand__letters {
        margin: 1rem 0 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
        a {
            display: flex;
            background: color(base, PrimaryBlue);
            color: color(base, White);
            width: 4rem;
            height: 4rem;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 2rem;
            border-radius: 1rem;
            @include breakpoint(medium) {
                &:hover {
                    background: color(base, PrimaryBlueHover);
                }
            }
        }
    }
    .--content {
        margin: 4rem 0;
        h2, h3 {
            color: color(base, Black);
            font-weight: 500;
            font-size: 20px;
            margin: 0 0 20px;
        }
        p {
            line-height: 24px;
            font-size: 14px;
            margin: 0 0 20px;
            a {
                color: color(base, PrimaryPink);
                text-decoration: underline;
                font-weight: 400;
                &:hover {
                    color: darken(color(gray, Gray-300), 5%);
                }
            }
            &:last-child {
                margin: 0;
            }
        }
    }
    .popular__brands {
        background: color(base, TertiaryBlue);
        padding: 2rem 0 4rem;
        margin: 2rem 0;
        @include breakpoint(small only) {
            overflow: hidden;
        }
        .grid-container {
            @include breakpoint(small only) {
                padding: 0;
            }
            h2 {
                font-size: 2.2rem;
                font-weight: 500;
                margin: 0 0 2rem;
                color: color(base, PrimaryBlue);
                @include breakpoint(small only) {
                    padding: 0 2rem;
                    margin: 0 0 1rem;
                }
            }
            .popular__brands--slider {
                margin: 0 -1rem;
                .slider__container {
                    display: flex;
                    position: relative;
                    .slider__control {
                        background-image: url("data:image/svg+xml,%3Csvg width='19' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.361.6c-.853 0-2.881 1.586-6.084 4.763C3.357 10.2.4 13.25.4 14.513c1.359 2.389 6.44 7.284 15.241 14.686.185 0 .465.064.84.201 1.284-.27 1.919-.793 1.919-1.576 0-.29-3.039-3.138-9.123-8.553a27.832 27.832 0 0 1-4.718-4.959c1.946-2.296 6.186-6.207 12.721-11.734v-.602c0-.666-.642-1.126-1.919-1.376' fill='%23D9D9D9' fill-rule='evenodd'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 2.2rem;
                        height: 3.2rem;
                        position: absolute;
                        z-index: 4;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -4em;
                        cursor: pointer;
                        @media screen and (max-width: 1460px) {
                            display: none;
                        }
                        &._next {
                            left: auto;
                            right: -3rem;
                            transform: translateY(-50%) rotate(-180deg);
                        }
                        @include breakpoint(medium) {
                            &:hover {
                                background-image: url("data:image/svg+xml,%3Csvg width='19' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.361.6c-.853 0-2.881 1.586-6.084 4.763C3.357 10.2.4 13.25.4 14.513c1.359 2.389 6.44 7.284 15.241 14.686.185 0 .465.064.84.201 1.284-.27 1.919-.793 1.919-1.576 0-.29-3.039-3.138-9.123-8.553a27.832 27.832 0 0 1-4.718-4.959c1.946-2.296 6.186-6.207 12.721-11.734v-.602c0-.666-.642-1.126-1.919-1.376' fill='#{color(gray, Gray-300)}' fill-rule='evenodd'/%3E%3C/svg%3E");
                            }
                        }
                        &.swiper-button-disabled {
                            display: none;
                        }
                    }
                    .swiper-slide {
                        width: 28rem;
                        @include breakpoint(medium) {
                            width: 20%;
                        }
                        @include breakpoint(small only) {
                            &:first-child {
                                margin-left: 2rem;
                            }
                        }
                        > div {
                            padding: 1rem;
                            a {
                                display: flex;
                                background: color(base, White);
                                max-width: 25.6rem;
                                height: 15rem;
                                align-items: center;
                                justify-content: center;
                                border: .1rem solid color(gray, Gray-100);
                                > span {
                                    display: inline-block;
                                    padding: 0 2rem;
                                    font-size: 3.2rem;
                                    font-weight: 600;
                                    line-height: 1.1;
                                    text-align: center;
                                }
                                img {
                                    object-fit: scale-down;
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .letter__block.cell {
        @include breakpoint(medium) {
            width: 20%;
        }
        margin-top: 2rem;
        margin-bottom: 2rem;
        strong {
            display: block;
            font-size: 3.2rem;
            font-weight: 600;
            color: color(base, PrimaryBlue);
            margin: 0 0 1rem;
        }
        ul li {
            a {
                color: color(base, Black);
                font-size: 1.5rem;
                line-height: 3.1rem;
                @include breakpoint(medium) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
