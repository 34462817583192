.catalog__category {
    margin: 2rem 0 10rem;
    .page-builder__content-block & {
        margin: 2rem 0 0;
    }
    @include breakpoint(small only) {
        margin: 0 0 6rem;
    }
    #offCanvas-filter {
        @include breakpoint(medium) {
            z-index: 1;
        }
        .catalog__category-filter {
            @include breakpoint(medium) {
                padding: 0 0 4rem;
            }
        }
    }
    .category-slider-container {
        .swiper-navigation {
            cursor: pointer;
        }
        .image__slider-container {
            .swiper-container {
                margin: 0 0 4rem;
                @include breakpoint(small only) {
                    padding: 0 !important;
                    margin: 0 0 2rem;
                }
            }
        }
    }
    .category__back-btn {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, PrimaryPink)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 19l-7-7 7-7' /%3E%3C/svg%3E");
        background-position: left center;
        background-repeat: no-repeat;
        padding-left: 2rem;
        background-size: 1.4rem;
        display: block;
        margin: 0 0 2rem;
    }
    .category__subtitle {
        margin: 4rem 0 8rem;
        width: 85%;
        padding-left: 1rem;
        @include breakpoint(small only) {
            width: 100%;
        }
        h2 {
            font-size: 2rem;
            line-height: 2.6rem;
            font-weight: 500;
            color: color(base, Black);
            margin: 0 0 1rem;
        }
        p {
            margin: 0 0 1rem;
        }
    }
    .catalog__category-top {
        h1 {
            line-height: 28px;
            font-size: 26px;
            margin: 2rem 0;
            font-weight: 500;
            color: color(base, PrimaryBlue);
            @include breakpoint(small only) {
                line-height: 24px;
                font-size: 20px;
                margin: 5px 0 15px;
            }
        }
        @include breakpoint(small only) {
            margin: 0;
            padding: 0;
        }
        .category__sub-description {
            position: relative;

            .catalog__category-sub-categories {
                &:not(#mobileCategoryPlaceholder) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                }

                ol {
                    .swiper-container {
                        position: relative;
                        .swiper-slide {
                            z-index: 1;
                            width: auto;
                            margin: 0 1rem 0 0;
                            padding: 1rem 0;
                            a {
                                background-color: color(base, SecondaryBlue);
                                font-size: 1.4rem;
                                color: color(base, White);
                                border-radius: 2rem;
                                padding: 1rem 1rem;
                                white-space: nowrap;
                            }
                        }
                        &:after {
                            content: '';
                            display: block;
                            width: 2rem;
                            height: 4.4rem;
                            background: linear-gradient(to right, transparent, white);
                            position: absolute;
                            z-index: 2;
                            top: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .catalog__category-list {
        position: relative;
        z-index: 1;
        .product__tile {
            @include breakpoint(small only) {
                padding: 0
            }
        }
    }
    .catalog__category-image {
        padding: 0 10px;
        position: relative;
        margin: 0 0 40px;
        height: 250px;
        ._content {
            color: white;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(0, -50%);
            padding: 0 40px;
            height: 250px;
            background: lighten(color(base, Black), 5%);
            @include breakpoint(medium up) {
                top: 45%;
            }
            @include breakpoint(large up) {
                top: 50%;
            }
            h1 {
                font-size: 18px;
                color: color(base, White);
            }
            span {
                color: color(base, White);
                p {
                    @include breakpoint(medium up) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                    @include breakpoint(large up) {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
            ._content-text {
                width: 70%;
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

            }
        }
        ._image.medium-12.cell {
            padding: 0;
            img {
                height: 250px;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .catalog__category-toolbar-top {
        position: relative;

        .toolbar__top {
            &:not(#toolbarPlaceholder) {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
            }

            display: block;
            margin: 0 0 2rem;
            @include breakpoint(small only) {
                flex: 100%;
                margin: 0 0 10px;
            }
            .toolbar__top-header {
                margin: 0 0 2rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                ._pagination {
                    @include breakpoint(small only) {
                        display: none;
                    }
                }
            }
            .toolbar__filter-container {
                margin-left: auto;
                display: flex;
                @include breakpoint(small only) {
                    flex: 100%;
                }
            }
            .mobile__filter-page-container {
                display: flex;
                width: 100%;
                margin: 15px 0 5px;

                span {
                    width: 50%;
                    font-size: 14px;
                    line-height: 17px;

                    &:nth-of-type(2) {
                        text-align: right;
                    }
                }
            }

            .mobile__filter-container {
                @include breakpoint(small only) {
                    padding: 0 10px 0 0;
                    width: 100%;
                    display: block;
                }
                span {
                    height: 38px;
                    line-height: 38px;
                    display: block;
                    border-radius: 3px;
                    font-size: 12px;
                    border: 1px solid color(gray, Gray-300);
                    padding: 0 15px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-adjustments-horizontal' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Ccircle cx='14' cy='6' r='2' /%3E%3Cline x1='4' y1='6' x2='12' y2='6' /%3E%3Cline x1='16' y1='6' x2='20' y2='6' /%3E%3Ccircle cx='8' cy='12' r='2' /%3E%3Cline x1='4' y1='12' x2='6' y2='12' /%3E%3Cline x1='10' y1='12' x2='20' y2='12' /%3E%3Ccircle cx='17' cy='18' r='2' /%3E%3Cline x1='4' y1='18' x2='15' y2='18' /%3E%3Cline x1='19' y1='18' x2='20' y2='18' /%3E%3C/svg%3E");
                    background-position: right 10px center;
                    background-size: 16px;
                    background-repeat: no-repeat;
                    @include breakpoint(small only) {
                        font-weight: 600;
                        border: none;
                        border-radius: 2rem;
                        color: color(base, White);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-adjustments-horizontal' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Ccircle cx='14' cy='6' r='2' /%3E%3Cline x1='4' y1='6' x2='12' y2='6' /%3E%3Cline x1='16' y1='6' x2='20' y2='6' /%3E%3Ccircle cx='8' cy='12' r='2' /%3E%3Cline x1='4' y1='12' x2='6' y2='12' /%3E%3Cline x1='10' y1='12' x2='20' y2='12' /%3E%3Ccircle cx='17' cy='18' r='2' /%3E%3Cline x1='4' y1='18' x2='15' y2='18' /%3E%3Cline x1='19' y1='18' x2='20' y2='18' /%3E%3C/svg%3E");
                        background-color: color(base, PrimaryBlue);
                    }
                }
            }
            .catalog__category-sort {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                @include breakpoint(small only) {
                    padding: 0 0 0 10px;
                }
            }
            .catalog__category-sorter {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 10px 0;
                @include breakpoint(small only) {
                    width: 100%;
                    margin: 0;
                }
                .off-canvas__filter_toggle {
                    width: 50%;
                }
                .sorting-container {
                    display: flex;
                    align-items: center;
                    @include breakpoint(small only) {
                        width: 100%;
                    }
                    .per-page {
                        display: flex;
                        align-items: center;
                        span {
                            flex-shrink: 1;
                            line-height: 40px;
                            font-size: 12px;
                            color: color(base, Black);
                        }
                        select {
                            flex: 1;
                            outline: none;
                            color: color(base, PrimaryBlue);
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(6.786632, 4.000000) rotate(-90.000000) translate(-6.786632, -4.000000) ' points='10.7866324 -1.57326478 9.57326478 -2.78663239 2.78663239 4 9.57326478 10.7866324 10.7866324 9.57326478 5.21336761 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                            font-size: 12px;
                            background-size: 1rem;
                            margin-left: 10px;
                            line-height: 32px;
                            height: 34px;
                            border: .2rem solid color(base, TertiaryBlue);
                            padding: 0 3rem 0 2rem;
                            background-position: right 1rem center;
                            @include breakpoint(small only) {
                                border: .2rem solid color(base, TertiaryBlue);
                                border-radius: 2rem;
                                font-size: 12px;
                                height: 38px;
                                line-height: 37px;
                                padding-left: 15px;
                                background-size: 12px;
                                margin-left: 0;
                                width: 100%;
                            }
                        }
                    }
                }
                .sort-container {
                    display: flex;
                    align-items: center;
                    @include breakpoint(small only) {
                        width: 100%;
                    }
                    span {
                        flex-shrink: 1;
                        line-height: 40px;
                        font-size: 12px;
                        color: color(base, Black);
                    }
                    select {
                        flex: 1;
                        outline: none;
                        color: color(base, PrimaryBlue);
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(6.786632, 4.000000) rotate(-90.000000) translate(-6.786632, -4.000000) ' points='10.7866324 -1.57326478 9.57326478 -2.78663239 2.78663239 4 9.57326478 10.7866324 10.7866324 9.57326478 5.21336761 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                        font-size: 12px;
                        background-size: 1rem;
                        border: .2rem solid color(base, TertiaryBlue);
                        line-height: 32px;
                        height: 34px;
                        padding: 0 3rem 0 2rem;
                        background-position: right 1rem center;
                        margin-left: 1rem;
                        @include breakpoint(small only) {
                            border: .2rem solid color(base, PrimaryBlue);
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='#{color(base, PrimaryBlue)}' fill-rule='nonzero' transform='translate(6.786632, 4.000000) rotate(-90.000000) translate(-6.786632, -4.000000) ' points='10.7866324 -1.57326478 9.57326478 -2.78663239 2.78663239 4 9.57326478 10.7866324 10.7866324 9.57326478 5.21336761 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                            border-radius: 2rem;
                            font-size: 12px;
                            height: 38px;
                            line-height: 37px;
                            padding-left: 15px;
                            background-size: 12px;
                            margin-left: 0;
                            width: 100%;
                            font-weight: 600;
                        }
                    }
                }
                .amount {
                    font-size: 12px;
                    line-height: 38px;
                    color: color(gray, Gray-300);
                }
            }
            .catalog__amount {
                padding: 2rem 0;
                span {
                    font-size: 1.4rem;
                }
            }
        }
    }

    .toolbar__bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 2rem 0 0;
        @include breakpoint(small only) {
            justify-content: center;
        }
        .product__per__page {
            margin: 0 0 20px;
            text-align: center;
            color: color(gray, Gray-300);
        }
    }

    .toolbar__active-filters {
        margin: 0 0 20px;
        ul {
            display: flex;
            li, a {
                padding-right: 7px;
                label, button {
                    cursor: pointer;
                    background-color: color(base, PrimaryBlue);
                    color: color(base, White);
                    font-size: 11px;
                    font-weight: 700;
                    line-height: 26px;
                    padding: 0 8px 0 18px;
                    border-radius: 5px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, White)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
                    background-size: 10px;
                    background-repeat: no-repeat;
                    background-position: center left 4px;
                    &:hover {
                        background-color: darken(color(base, PrimaryBlue), 5%);
                    }
                }
            }
        }
    }

    .category__description {
        &._full {
            .category__text._active {
                &:before {
                    display: none;
                }
            }
        }
        @include breakpoint(small only) {
            margin: 0 0 2rem;
        }
        ._actions {
            margin: 1rem 0 0;
            display: flex;
            justify-content: center;
        }
        .category__text {
            overflow: hidden;
            font-size: 1.4rem;
            max-height: 7rem;
            transition: max-height 0.5s ease;
            &._active {
                position: relative;
                &::before {
                    position: absolute;
                    bottom: 0;
                    height: 100%;
                    width: 100%;
                    content: '';
                    background: linear-gradient(to top,
                        rgba(255, 255, 255, 1) 0%,
                        rgba(255, 255, 255, 0) 30%
                    );
                    pointer-events: none;
                    @include breakpoint(small only) {
                        background: linear-gradient(to top,
                            rgba(255, 255, 255, 1) 20%,
                            rgba(255, 255, 255, 0) 80%
                        );
                    }
                }
            }
            ul {
                list-style: disc inside;
                li {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                }
            }
        }
        &._bottom {
            margin: 20px 0 40px;
            content-visibility: auto;
            contain-intrinsic-size: 300px;
        }
        h2, h3 {
            color: color(base, Black);
            font-weight: 500;
            font-size: 20px;
            margin: 0 0 20px;
        }
        p {
            line-height: 24px;
            font-size: 14px;
            margin: 0 0 20px;
            a {
                color: color(base, PrimaryPink);
                text-decoration: underline;
                font-weight: 400;
                &:hover {
                    color: darken(color(gray, Gray-300), 5%);
                }
            }
            &:last-child {
                margin: 0;
            }
        }
    }
}
