.checkout-cart_body {
    @include breakpoint(small only) {
        header {
            section {
                display: none;
            }
        }
    }
    footer {
        margin: 0;
        .newsletter__subscribe, .footer-container {
            display: none;
        }
    }
    header {
        .trustpilot-widget {
            margin: 0;
            margin-left: auto;
            max-width: 450px;
            width: 100%;
        }
    }
    .trustpilot-widget {
        margin: 4rem 0 0;
    }
    .checkout-cart {
        margin: 0 0 14rem;
        @include breakpoint(medium up) {
            min-height: calc(100vh - 27rem);
        }

        .breadcrumbs {
            display: none;
        }
        ._header {
            padding: 3rem 0 0;
            @include breakpoint(small only) {
                padding: 2rem 0;
            }
            ._holder {
                display: flex;
                align-items: center;
                margin: 0 0 3rem;
                position: relative;
                @include breakpoint(small only) {
                    margin: 0 0 1rem;
                }
                h1 {
                    @include head-font;
                    color: color(base, Black);
                    font-size: 2.4rem;
                    font-weight: 600;
                    margin: 0 0 1.5rem;
                    display: flex;
                    align-items: center;
                    @include breakpoint(small only) {
                        font-size: 2rem;
                    }
                    span {
                        font-size: 2.2rem;
                        font-weight: normal;
                        margin-left: .5rem;
                        @include breakpoint(small only) {
                            font-size: 1.6rem;
                        }
                    }
                    &._checkout-title {
                        margin: 0;
                        @include breakpoint(small only) {
                            padding-left: 2rem;
                        }
                    }
                }
                &._cart-title {
                    margin: 0;
                }
            }
            .messages, .cart__message {
                margin: 1.5rem 0 0;
            }
        }
        @media screen and (min-width: 768px) and (max-width: 1140px) {
            .breadcrumbs + span {
                > .grid-x.grid-padding-x {
                    .medium-13 {
                        width: 62.5%;
                    }
                    .medium-8 {
                        width: 37.5%;
                        margin-left: 0;
                    }
                }
            }
        }
        .cart__items {
            .cart-container {
                .cart-item-list-loader {
                    font-size: 1.4rem;
                    font-weight: normal;
                }
                .cart__item {
                    display: flex;
                    border-bottom: 2px solid color(base, TertiaryBlue);
                    padding: 3rem 0;
                    @include breakpoint(small only) {
                        padding: 3rem 0 2rem;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        &:last-child {
                            border: none;
                        }
                    }
                    > a {
                        display: flex;
                        background: color(base, White);
                        border-radius: .3rem;
                        img {
                            margin: 0 auto;
                            align-self: center;
                            object-fit: scale-down;
                            max-width: 12rem;
                            max-height: 12rem;
                            height: auto;
                            @include breakpoint(small only) {
                                max-width: 8rem;
                                align-self: flex-start;
                            }
                        }
                    }
                    .row__price {
                        position: relative;
                        margin-top: 1rem;
                        margin-right: auto;
                        color: color(base, PrimaryPink);
                        ._loading {
                            position: static;
                            ._spinner {
                                width: 2.5rem;
                                height: 2.5rem;
                                margin: 0;
                            }
                        }
                        span {
                            font-weight: normal;
                            font-size: 2rem;
                        }
                        .price__holder {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            .price__old-price {
                                margin: 0;
                            }
                        }
                        .price__old-price {
                            font-size: 1.3rem;
                            margin-right: 1rem;
                            text-decoration: line-through;
                            vertical-align: super;
                            color: color(base, DefaultFontColor);
                        }
                        .full-price--big {
                            font-size: 3rem;
                            font-weight: 600;
                        }
                        .full-price--small {
                            font-size: 2rem;
                            font-weight: 600;
                            position: relative;
                            top: 0;
                        }
                    }
                    select {
                        margin-top: auto;
                        width: 7.6rem;
                        padding: 0 2.4rem;
                        @include breakpoint(small only) {
                            padding: 0 2rem;
                        }
                        &.disabled {
                            border: 1px solid color(gray, Gray-200);
                            color: color(gray, Gray-200);
                            cursor: not-allowed;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-200)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m19 9-7 7-7-7'/%3E%3C/svg%3E");
                        }
                    }
                    ._information {
                        display: inline-flex;
                        flex-flow: column;
                        padding: 0 2rem 2rem;
                        align-items: flex-start;
                        @include breakpoint(small only) {
                            max-width: 60%;
                            padding: 0 1rem 2rem;
                        }
                        > a {
                            @include head-font;
                            font-size: 1.8rem;
                            line-height: 2.4rem;
                            font-weight: 500;
                            color: color(base, PrimaryBlue);
                            @include breakpoint(small only) {
                                font-size: 1.6rem;
                                line-height: 2.4rem;
                            }
                            &:hover {
                                color: color(base, PrimaryBlueHover);
                            }
                            &.item__bundle-url {
                                background: color(base, SecondaryPink);
                                border-radius: 1.3rem;
                                display: inline-block;
                                color: color(base, White);
                                padding: 0 1rem;
                                line-height: 2.6rem;
                                font-size: 1.2rem;
                                margin: 1rem 0 0;
                                @include breakpoint(small only) {
                                    width: 100%;
                                }
                                &:hover {
                                    background: darken(color(base, SecondaryPink), 15%);
                                }
                            }
                        }
                        .cart__item-actions {
                            display: flex;
                            flex-flow: row-reverse;
                            justify-content: space-between;
                            margin: 2rem 0 0;
                            width: 100%;
                            select {
                                max-width: 6rem;
                            }
                            .row__price {
                                margin-right: 0;
                                margin-top: 0;
                            }
                        }
                        ._rating {
                            margin: .5rem 0 0;
                        }
                        .product-usp, .available-from {
                            margin: 1rem 0 0;
                        }
                        ul {
                            margin: 3rem 0 1rem;
                            @include breakpoint(small only) {
                                margin: 1rem 0;
                            }
                            li {
                                font-size: 1.4rem;
                                line-height: 2rem;
                                font-weight: normal;
                                color: color(gray, Gray-300);
                            }
                        }
                    }
                    ._actions {
                        margin-left: auto;
                        display: flex;
                        flex-flow: column;
                        padding: 0 0 2rem;
                        @include breakpoint(small only) {
                            align-items: flex-start;
                        }
                        @include breakpoint(medium) {
                            padding: 0 0 3rem;
                            justify-content: space-between;
                            flex-basis: 15%;
                        }
                        .price__holder {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            .price__old-price {
                                font-size: 1.3rem;
                                text-decoration: line-through;
                                vertical-align: super;
                                margin-right: auto;
                                padding-left: 2rem;
                            }
                        }
                        ._remove {
                            cursor: pointer;
                            width: 2.4rem;
                            height: 2.4rem;
                            margin-left: auto;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(gray, Gray-200)}'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                            @include breakpoint(medium) {
                                @include transition;
                                &:hover {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(gray, Gray-300)}'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                                }
                            }
                        }
                    }
                    .cart__stock {
                        margin-top: 5px;
                        @include delivery;
                    }
                }
            }
        }
        .cart__crosssell {
            h4 {
                text-transform: uppercase;
                margin: 3rem 0;
                text-align: center;
                font-size: 2rem;
            }
            .grid-container {
                padding: 0;
                margin: 0 -1rem;
                .swiper-wrapper {
                    .swiper-slide {
                        width: 33.33333344%;
                        @include breakpoint(small only) {
                            width: 50%;
                        }
                        li {
                            padding: 0 1rem;
                            > a .product__holder h3 {
                                font-size: 1.2rem;
                                margin: 0 0 3rem;
                            }
                        }
                    }
                }
            }
        }
        .cart-empty {
            p {
                font-size: 1.4rem;
                a {
                    font-size: 1.4rem;
                    text-decoration: underline;
                }
            }
        }
        .cart__side-bar-message {
            margin-bottom: 2rem;
            .message {
                display: block;
                color: color(base, DefaultFontColor);
                p {
                    display: inline;
                }
                .label__tooltip {
                    display: inline-block;
                    line-height: 2.4rem;
                    vertical-align: text-bottom;
                }
                a{
                    color: color(base, DefaultFontColor);
                    font-weight: bold;
                }
            }
        }
        .cart__side-bar-container {
            @include breakpoint(small only) {
                margin-top: 2rem;
            }
            .cart__side-bar {
                display: flex;
                flex-flow: column;
                border-radius: 0.5rem;
                background: color(base, LightSnow);
                @include breakpoint(medium) {
                    background: color(base, LightSnow);
                    padding: 0;
                    margin: 0 0 4rem;
                    border-radius: 0.5rem;
                }
                > h3 {
                    text-align: center;
                    margin: 2rem 0 2rem;
                    font-weight: 500;
                    font-size: 1.6rem;
                }
                .cart__coupon {
                    padding: 2rem;
                    border-top: 2px solid color(gray, Gray-200);
                    color: color(base, PrimaryBlue);
                    @include breakpoint(small only) {
                        order: 1;
                    }
                    .coupon-code__container {
                        position: relative;
                        ._loading {
                            background: transparent;
                            position: static;
                            ._spinner {
                                margin: 0 auto;
                                width: 3rem;
                                height: 3rem;
                            }
                        }
                        .toggle-coupon {
                            display: flex;
                            align-items: center;
                            label {
                                font-size: 1.3rem;
                                margin-right: 1rem;
                                line-height: 2rem;
                                color: color(gray, Gray-400);
                                font-weight: 100;
                            }
                            .vue-js-switch.toggled {
                                .v-switch-core {
                                    background-color: color(base, PrimaryBlue);
                                }
                            }
                        }
                        .form-holder {
                            display: flex;
                            margin: 2rem 0 0;
                            input {
                                border-radius: 1.8rem 0 0 1.8rem;
                            }
                            button {
                                font-family: 'Laslo', sans-serif;
                                font-weight: 500;
                                border-radius: 0 1.8rem 1.8rem 0;
                                &._cancel {
                                    border-radius: 1.8rem;
                                }
                            }
                        }
                    }
                }
                .totals-container {
                    padding: 2rem;
                    @include breakpoint(small only) {
                        background: color(base, LightSnow);
                        order: 3;
                    }
                    .totals-container__totals {
                        position: relative;
                        min-height: 10rem;
                        ._loading {
                            background-color: transparent;
                        }
                        ul {
                            li {
                                display: flex;
                                span {
                                    display: block;
                                    flex: 1;
                                    color: color(base, DefaultFontColor);
                                    font-size: 1.4rem;
                                    line-height: 2.6rem;
                                    &._free {
                                        color: color(alert, Success);
                                    }
                                    &:last-child {
                                        text-align: right;
                                    }
                                }
                                &._grand {
                                    border-top: 1px solid color(gray, Gray-100);
                                    margin: .8rem 0 0;
                                    padding: .8rem 0 0;
                                    span {
                                        font-weight: 600;
                                        font-size: 1.8rem;
                                    }
                                }
                                small {
                                    font-size: 1.2rem;
                                    color: color(gray, Gray-400);
                                }
                                &.subtotal {
                                    border-bottom: 1px solid color(gray, Gray-100);
                                    &._normal {
                                        border-bottom: none;
                                    }
                                    padding: 0 0 .8rem;
                                }
                                &.total-saving, &.minimum-shipping {
                                    font-style: italic;
                                    span {
                                        font-size: 1.3rem;
                                    }
                                }
                                &.total-saving {
                                    margin: 0 0 .8rem;
                                }
                                &.subtotal, &.shipping {
                                    &._normal {
                                        font-weight: 400;
                                    }
                                    span {
                                        font-weight: 600;
                                    }
                                }
                            }

                            &.cart__items {
                                border-bottom: .1rem solid color(gray, Gray-100);
                                padding: 0 0 1rem;
                                li span:last-child {
                                    flex: 0;
                                }
                            }
                        }
                    }
                }
                ._actions {
                    padding: 2rem;
                    border-top: .2rem solid color(gray, Gray-100);
                    @include breakpoint(small only) {
                        background: color(base, LightSnow);
                        order: 4;
                    }
                    button {
                        width: 100%;
                        line-height: 4rem;
                        border-radius: 2.2rem;
                        font-size: 1.6rem;
                        &._cart-loading {
                            background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, PrimaryPink)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, PrimaryPink)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, PrimaryPink)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, PrimaryPink)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                            background-repeat: no-repeat;
                            background-position: left 1.5rem center;
                            background-size: 2rem;
                        }
                        &:disabled {
                            background-color: color(gray, Gray-200);
                        }
                    }
                }
                @include breakpoint(small only) {
                    .cart__crosssell {
                        order: 5;
                        padding: 0 2rem;
                    }
                }
                .side-bar__bottom {
                    @include breakpoint(small only) {
                        background: color(base, White);
                        order: 6;
                    }
                    display: flex;
                    flex-flow: column;
                    padding: 3rem;
                    border-top: 2px solid color(gray, Gray-200);
                    justify-content: center;
                }
            }
            ._usp {
                @include breakpoint(small only) {
                    margin: 2rem 0 0;
                }
            }
        }
        .tweakwise-recommendation {
            @include breakpoint(small only) {
                margin: 4rem -1rem;
                padding: 0;
            }
        }
    }
    ._button.--bundle{
        margin-top: 1rem;
        span{
            pointer-events: none;
        }
        @include breakpoint(small only) {
            text-align: center;
            font-size:1.3rem;
            padding:0 1.3rem;
        }
    }
    .bundle-items{
        padding:1.5rem;
    }
    .bundle-item{
        display:flex;
        border-bottom: .1rem solid color(base, TertiaryBlue);
        margin-bottom: 1.6rem;
        padding-bottom: 1.6rem;
        img{
            width: 3rem;
            margin-right: 1rem;
        }
        span{
            display:block;
            font-size: 1.4rem;
        }
        &__name{
            font-weight: 700;
        }
    }
}

p.--secure {
    margin: 1rem 0 0;
    font-weight: normal;
    font-size: 1.4rem;
    color: color(alert, Success);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(alert, Success)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z' /%3E%3C/svg%3E");
    background-size: 1.5rem;
    background-repeat: no-repeat;
    padding-left: 2rem;
    background-position: left center;
}
