._pagination {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        gap: .5rem;
        li {
            a,
            span {
                @include transition;
                display: block;
                cursor: pointer;
                text-align: center;
                font-size: 12px;
                border: .1rem solid color(base, TertiaryBlue);
                border-radius: 2px;
                background: color(base, White);
                line-height: 36px;
                width: 36px;
                height: 36px;
                @include breakpoint(medium down) {
                    font-size: 12px;
                }
                &:hover {
                    background: color(base, TertiaryBlue);
                    color: color(base, PrimaryPink);
                    font-weight: 500;
                }
                &._current {
                    color: color(base, White);
                    background: color(base, PrimaryPink);
                    pointer-events: none;
                    font-weight: 500;
                    border: .1rem solid darken(color(base, PrimaryPink), 10%);
                }
                &._next {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='22px' viewBox='0 0 11 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='#{color(base, Black)}' fill-rule='nonzero' transform='translate(5.500000, 9.000000) rotate(180.000000) translate(-5.500000, -9.000000) ' points='11 1.60909091 9.33161954 0 0 9 9.33161954 18 11 16.3909091 3.33676093 9'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 6px;
                    &:hover {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='22px' viewBox='0 0 11 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='#{color(base, PrimaryPink)}' fill-rule='nonzero' transform='translate(5.500000, 9.000000) rotate(180.000000) translate(-5.500000, -9.000000) ' points='11 1.60909091 9.33161954 0 0 9 9.33161954 18 11 16.3909091 3.33676093 9'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    }
                }
                &._prev {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='22px' viewBox='0 0 11 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='#{color(base, Black)}' fill-rule='nonzero' transform='translate(5.500000, 9.000000) scale(-1, 1) rotate(180.000000) translate(-5.500000, -9.000000) ' points='11 1.60909091 9.33161954 0 0 9 9.33161954 18 11 16.3909091 3.33676093 9'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 6px;
                    &:hover {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='22px' viewBox='0 0 11 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='#{color(base, PrimaryPink)}' fill-rule='nonzero' transform='translate(5.500000, 9.000000) scale(-1, 1) rotate(180.000000) translate(-5.500000, -9.000000) ' points='11 1.60909091 9.33161954 0 0 9 9.33161954 18 11 16.3909091 3.33676093 9'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    }
                }
            }
        }
    }
}
