footer {
    background: color(base, SecondaryBlue);
    position: relative;
    color: color(base, White);
    padding: 2rem 0;
    &::before {
        content: '';
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjQxbW0iIGhlaWdodD0iMi4zOG1tIiB2aWV3Qm94PSIwIDAgMjMuODUgNi43NCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6Izc0YjhlMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmJ1YmJseS1lZGdlLWJsdWU8L3RpdGxlPjxwYXRoIGQ9Ik0xMS45MyAwQTE0IDE0IDAgMCAwIDAgNi43NGgyMy44NUExNCAxNCAwIDAgMCAxMS45MyAweiIgY2xhc3M9ImEiLz48L3N2Zz4=");
        background-repeat: repeat-x;
        background-size: contain;
        background-position: center;
        height: .7rem;
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
    }
    .footer__block {
        padding: 2rem 0;
        h3 {
            font-size: 1.4rem;
            font-weight: 700;
            margin: 0 0 1rem;
            &:not(:first-of-type) {
                margin: 0.5rem 0 0;
                @include breakpoint(small only) {
                    margin-top: 4rem;
                }
            }
            @include breakpoint(medium) {
                font-size: 2rem;
                line-height: 2.6rem;
                font-weight: 500;
                margin: 0 0 2rem;
            }
            @include breakpoint(small only) {
                font-size: 1.6rem;
            }
        }
        p {
            margin: 0 0 1.5rem;
        }
        a, p, strong {
            color: color(base, White);
            font-size: 1.6rem;
            line-height: 2.4rem;
            display: block;
        }
        ul:not(.--social) {
            li {
                @include breakpoint(small only) {
                    padding: 1rem 0;
                }
                a {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='White'%3E%3Cpath fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-size: 1.4rem;
                    background-position: left center;
                    padding-left: 2rem;
                    @include breakpoint(medium) {
                        transition: all .1s ease-in-out;
                        line-height: 3.2rem;
                        font-size: 1.6rem;
                        &:hover {
                            color: color(base, White);
                            padding-left: 2.6rem;
                            opacity: .6;
                        }
                    }
                }
            }
        }
        &.--stores {
            a {
                font-weight: 700;
                @include breakpoint(medium) {
                    &:hover {
                        text-decoration: underline;
                        color: color(base, White);
                    }
                }
            }
        }
        ul.--social {
            display: flex;
            margin: 2rem 0 3rem;
            gap: 1rem;
            li a {
                display: flex;
                width: 5rem;
                height: 5rem;
                background: color(base, White);
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                padding: 0.7rem;
            }
        }
        .newsletter__container {
            p {
                margin: 0.3rem 0 1.5rem;
                font-size: 1.5rem;
            }
            .newsletter__container-form {
                display: flex;
                input {
                    flex: 1;
                    border-radius: 2rem 0 0 2rem;
                    border-color: transparent;
                }
                button {
                    flex-shrink: 1;
                    border-radius: 0 2rem 2rem 0;
                }
            }
        }
    }
    .--contact {
        @include breakpoint(small only) {
            display: flex;
            flex-direction: column;
            margin: 2rem 0;
        }
        @include breakpoint(medium) {
            margin-right: auto;
            text-align: left;
            display: flex;
            flex-flow: column;
            position: relative;
            top: -2rem;
        }
        a {
            color: color(base, White);
            display: inline-flex;
            text-align: center;
            font-size: 1.6rem;
            line-height: 2.6rem;
            font-weight: 700;
            margin: 0 0 1rem;
            background-repeat: no-repeat;
            background-size: 2.5rem;
            background-position: left center;
            padding-left: 4rem;
            &:nth-child(1) {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(base, White)}'%3E%3Cpath d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' /%3E%3C/svg%3E");
            }
            &:nth-child(2) {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-whatsapp' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9' /%3E%3Cpath d='M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1' /%3E%3C/svg%3E");
            }
            &:nth-of-type(3), .mail {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(base, White)}'%3E%3Cpath d='M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z' /%3E%3Cpath d='M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z' /%3E%3C/svg%3E");
                margin: 1.5rem 0 0;
            }
        }
    }
    .absolute__footer {
        margin: 2rem 0 0;
        padding: 2rem 0;
        @include breakpoint(medium) {
            margin: -2rem 0 0;
            padding: 2rem 0 0;
        }
        @include breakpoint(small only) {
            padding: 0;
            margin: 0;
        }
        .grid-container {
            display: flex;
            @include breakpoint(medium) {
                justify-content: space-between;
                border-top: .1rem solid color(base, White);
                gap: 2rem;
                align-items: center;
                padding: 3rem 0 0;
            }
            @include breakpoint(small only) {
                flex-flow: column;
            }
            > div {
                margin: 0 0 1rem;
                padding: 0 2rem;
                &.--awards {
                    @include breakpoint(small only) {
                        padding: 0;
                    }
                    img {
                        width: 15rem;
                        height: auto;
                        margin: 0 auto;
                        @include breakpoint(small only) {
                            margin: 0;
                        }
                    }
                }
                &.--feedback {
                    display: flex;
                    gap: 3rem;
                    @include breakpoint(small only) {
                        column-gap: 3rem;
                        row-gap: 1rem;
                        flex-flow: wrap;
                        margin: 1rem 0 0;
                        padding: 0;
                    }
                    a {
                        color: color(base, White);
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    span {
                        font-size: 1.4rem;
                        line-height: 2.4rem;
                    }
                }
            }
        }
    }
}


#robin_tab_container {
    bottom: 85px !important;
}
