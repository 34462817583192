.checkout-success-page {
    padding: 3rem 0 10rem;
    @include breakpoint(small only) {
        padding: 1.5rem 0 8rem;
    }
    .breadcrumbs {
        display: none;
    }
    h1 {
        color: color(base, PrimaryBlue);
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 2.8rem;
        margin: 0 0 2rem;
        @include breakpoint(small only) {
            font-size: 22px;
        }
    }
    .trustpilot-widget {
        margin: 6rem 0 0;
    }
    .order__information {
        margin: 10px 0 0;
        .order__address {
            margin: 20px 0 0;
            background: color(base, TertiaryBlue);
            padding: 20px;
            border-radius: 0.5rem;
            h4 {
                font-size: 2rem;
                margin: 0 0 1.5rem;
                font-weight: 500;
                color: color(base, Black);
            }
            ul {
                li {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                }
            }
        }
    }
}
