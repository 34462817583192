.stores-overview {
    .stores__container {
        margin: 0 0 8rem;
        .store__banner-container {
            .image__slider-container {
                margin: 3rem 0;

                img {
                    max-height: 40rem;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .store-title {
            padding: 3rem 0 0;

            h1 {
                color: color(base, PrimaryBlue);
                font-size: 2.6rem;
                font-weight: 500;
                line-height: 2.8rem;
                margin: 0 0 2rem;
            }
        }

        .store__list {
            @include breakpoint(small only) {
                margin: 0 0 6rem;
            }
            .stores__list-container {
                .stores__list-single {
                    margin: 3rem 0 0;
                    .store__list-data {
                        border: 1px solid color(gray, Gray-200);
                        border-radius: 0.5rem;
                        overflow: hidden;
                        &:hover {
                            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
                        }

                        .store__list-image {
                            img {
                                max-height: 20rem;
                                width: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }

                    .store__list-content {
                        padding: 1.5rem;

                        h2 {
                            font-size: 2rem;
                            color: color(base, Black);
                            font-weight: 500;
                            margin: 0 0 .5rem;
                        }

                        address {
                            font-size: 1.4rem;
                            font-style: normal;
                            line-height: 1.8rem;
                            color: color(base, DefaultFontColor);
                        }
                        .opening-times {
                            margin: 1rem 0 0;
                            &.open {
                                color: color(alert, Success);
                                &:before {
                                    content: '';
                                    display: inline-block;
                                    width: 1rem;
                                    height: 1rem;
                                    border-radius: 50%;
                                    background-color: color(alert, Success);
                                    margin-right: .5rem;
                                }
                            }
                            &.closed {
                                color: color(base, PrimaryPink);
                                &:before {
                                    content: '';
                                    display: inline-block;
                                    width: 1rem;
                                    height: 1rem;
                                    border-radius: 50%;
                                    background-color: color(base, PrimaryPink);
                                    margin-right: .5rem;
                                }
                            }
                        }
                    }
                }

                @include breakpoint(small only) {
                    padding: 1rem 0;
                }

                &:first-of-type {
                    padding-left: 0;
                }

                &:last-of-type {
                    padding-right: 0;
                }
            }
        }
    }
}

.stores-detail {
    margin: 0 0 8rem;
    .store__detail-container {
        .store__banner-container {
            margin: 1rem 0 0;
            .image__slider-container {
                position: relative;
                .swiper-container {
                    padding: 0 !important;
                    img {
                        max-height: 50rem;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 0.5rem;
                    }
                }
            }
        }
        .store__detail-item {
            @include breakpoint(small only) {
                margin: 0 0 8rem;
            }
            .store__detail-banner {
                margin: 3rem 0;
                @include breakpoint(small only) {
                    margin: 3rem 0 2rem;
                }
                .store__info {
                    @include breakpoint(small only) {
                        margin: 0 0 2rem;
                    }
                    .sub-title {
                        margin: 0 0 1rem;
                        display: block;
                        font-size: 1.6rem;
                        font-weight: 600;
                    }
                }
                .store__detail-regular-hours {
                    font-size: 1.4rem;
                    padding: 2rem;
                    background: color(base, LightSnow);
                    border-radius: 0.5rem;
                    .store__detail-opening {
                        ul {
                            li {
                                line-height: 2.4rem;
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                @include breakpoint(small only) {
                                    width: 100%;
                                }
                                span {
                                    flex: 1;
                                }
                                .opening_time {
                                    flex: 1;
                                    display: flex;
                                    flex-direction: column;
                                    .additional_hours {
                                        font-weight: 500;
                                        font-size: 1.1rem;
                                    }
                                }
                            }
                        }
                    }
                }
                .store__additional-hours, .store__address-info {
                    padding: 2rem;
                    background: color(base, LightSnow);
                    border-radius: 0.5rem;
                    font-size: 1.4rem;
                }
                .store__address-info {
                    font-size: 1.4rem;
                    address {
                        font-style: normal;
                    }
                }
            }
            .stores__detail-container {
                h1 {
                    color: color(base, PrimaryBlue);
                    font-size: 2.6rem;
                    font-weight: 500;
                    line-height: 2.8rem;
                    margin: 0 0 2rem;
                }
                .store-image {
                    @include breakpoint(small only) {
                        margin: 2rem 0 0;
                    }
                }
                .store__detail-description {
                    margin: 0 0 2rem;
                }
            }
        }
    }
}
