.cms-page {
    margin: 0 0 6rem;

    &.error-page {
        padding: 1rem 0 0;

        .breadcrumbs {
            display: none;
        }
    }

    .content {
        h1 {
            line-height: 28px;
            font-size: 26px;
            margin: 0 0 2rem;
            font-weight: 500;
            color: color(base, PrimaryBlue);
            @include breakpoint(small only) {
                line-height: 24px;
                font-size: 20px;
                margin: 0 0 2rem;
            }
        }

        h2, h3, h4 {
            font-size: 2rem;
            line-height: 2.4rem;
            font-weight: 500;
            margin: 0 0 1rem;
            @include breakpoint(small only) {
                font-size: 1.8rem;
                line-height: 2rem;
            }
        }

        p {
            font-size: 1.4rem;
            line-height: 2.4rem;
            margin: 0 0 1rem;
        }

        a:not(._button) {
            color: color(base, PrimaryPink);
            text-decoration: underline;
            font-weight: 500;

            &:hover {
                color: darken(color(base, PrimaryPink), 5%);
            }
        }

        ul, ol {
            list-style: disc inside;
            margin: 0 0 2rem;
            font-size: 1.4rem;
            line-height: 2.4rem;
        }

        ul {
            li.heart {
                padding-left: 3.5rem;
                position: relative;

                &:before {
                    content: '';
                    display: inline-block;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCA0NCA0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImhlYXJ0Ij4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTM4LjY2NjcgMTAuMzE1MUMzNy4xNzc4IDcuMjU5NSAzMi44ODg5IDQuNzU5NSAyNy45IDYuMjE1MDZDMjUuNTE2MSA2LjkwMzY1IDIzLjQzNjEgOC4zODAzNyAyMiAxMC40MDM5QzIwLjU2MzkgOC4zODAzNyAxOC40ODM5IDYuOTAzNjUgMTYuMSA2LjIxNTA2QzExLjEgNC43ODE3MiA2LjgyMjIyIDcuMjU5NSA1LjMzMzMzIDEwLjMxNTFDMy4yNDQ0NCAxNC41OTI4IDQuMTExMTEgMTkuNDAzOSA3LjkxMTExIDI0LjYxNTFDMTAuODg4OSAyOC42OTI4IDE1LjE0NDQgMzIuODI2MiAyMS4zMjIyIDM3LjYyNjJDMjEuNTE3NSAzNy43Nzg0IDIxLjc1OCAzNy44NjExIDIyLjAwNTYgMzcuODYxMUMyMi4yNTMyIDM3Ljg2MTEgMjIuNDkzNyAzNy43Nzg0IDIyLjY4ODkgMzcuNjI2MkMyOC44NTU2IDMyLjgzNzMgMzMuMTIyMiAyOC43MzczIDM2LjEgMjQuNjE1MUMzOS44ODg5IDE5LjQwMzkgNDAuNzU1NiAxNC41OTI4IDM4LjY2NjcgMTAuMzE1MVYxMC4zMTUxWiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8L3N2Zz4K);
                    background-repeat: no-repeat;
                    background-color: color(base, PrimaryPink);
                    background-size: 1.5rem;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    vertical-align: middle;
                    background-position: center;
                    position: absolute;
                    left: 0;
                    top: .5rem;
                }
            }
        }

        .flowbox__buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 50px;
            cursor: pointer;
            gap: 10px;
        }

        ol {
            list-style: decimal inside;
        }

        table {
            width: 100%;
            margin: 0 0 2rem;
            background: color(base, White);

            tr {
                &:nth-child(even) {
                    background: color(base, TertiaryBlue);
                }

                th {
                    font-weight: 500;
                }

                th, td {
                    text-align: left;
                    border: .1rem solid color(gray, Gray-200);
                    border-collapse: collapse;
                    padding: .4rem .6rem;
                }
            }
        }

    }

    .content-block {
        margin: 0 0 8rem;

        ._holder {
            ._image {
                margin: 0 0 2rem;
            }

            ._content {
                h2 {
                    color: color(base, PrimaryBlue);
                }
            }
        }
    }

    .content-block-left, .content-block-right {
        margin: 0 0 8rem;

        ._holder {
            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                @include breakpoint(small only) {
                    margin: 2rem 0;
                }
            }

            h2 {
                color: color(base, PrimaryBlue);
            }
        }
    }

    .klantenservice {
        .small-24.medium-6.cell {
            margin: 0 0 2rem;
            @include breakpoint(small only) {
                margin: 2rem 0;
            }

            div {
                border: 0.1rem solid color(base, LightSnow);
                padding: 1.5rem;
                border-radius: 0.5rem;
                background: color(base, LightSnow);

                a {
                    text-decoration: none;
                }

                h3 {
                    text-align: center;
                    color: color(base, Black);
                    text-decoration: none;
                }

                p {
                    text-align: center;
                    color: color(base, Black);
                    text-decoration: none;
                }

                img {
                    margin: 0 auto;
                    max-height: 10rem;
                    width: auto;
                }
            }
        }
    }

    .klantenservice-algemeen {
        margin: 2rem 0 8rem;
    }

    .klantenservice-title {
        color: color(base, Black);
        margin: 6rem 0 0;
    }

    .cms-accordion {
        ul {
            list-style: none;
            margin: 0;
            line-height: 3.5rem;

            li {
                position: relative;

                &.question {
                    border-top: .1rem solid color(gray, Gray-200);
                    font-weight: 600;
                    line-height: 2.5rem;
                    padding: .5rem 2.5rem .5rem 0;
                    @include breakpoint(small only) {
                        padding-right: 3rem;
                    }

                    &:hover {
                        color: color(base, PrimaryBlue);
                        cursor: pointer;
                    }

                    &::after {
                        content: '';
                        display: inline-block;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: 1.3rem;
                        width: 2rem;
                        height: 2rem;
                        position: absolute;
                        transform: translateY(-50%);
                        top: 50%;
                        right: 0;
                    }

                    &._active {
                        &::after {
                            content: '';
                            display: inline-block;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5 15l7-7 7 7' /%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-size: 1.3rem;
                            width: 2rem;
                            height: 2rem;
                            position: absolute;
                            transform: translateY(-50%);
                            top: 50%;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    .klantenservice-contact {
        margin: 3rem 0 0;

        .--holder {
            display: flex;
            flex-wrap: wrap;

            .-content {
                flex: 1 1 33%;
                padding: 2rem;
                @include breakpoint(small only) {
                    flex: auto;
                }

                h3 {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    color: color(base, Black);
                }

                p {
                    color: color(base, DefaultFontColor);
                }
            }
        }
    }
}

table.CookieDeclarationTable {
    font-size: 1.2rem;
}

div.CookieDeclarationType {
    border: none;
}
