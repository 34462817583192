.modal__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99992;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal__wrapper {
        background: color(base, White);
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, .5);
        border-radius: 5px;
        max-width: 90vw;
        max-height: 90vh;
        .modal-header {
            padding: 15px 20px 0;
            display: flex;
            h4 {
                color: color(base, PrimaryBlue);
                font-size: 1.8rem;
            }
            span {
                cursor: pointer;
                display: block;
                width: 20px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                margin-left: auto;
            }
        }
        .modal-content {
            padding: 2rem;
            font-size: 1.4rem;
            form {
                .add__review__form {
                    p {
                        margin: 0 0 1rem;
                    }
                    input {
                        margin: 0 0 1rem;
                    }
                    textarea {
                        margin: 0 0 2rem;
                    }
                    .rating__group {
                        display: block;
                        float: left;
                        margin: 0 0 1rem;
                        > input {
                            opacity: 0;
                            position: absolute;
                            + label {
                                height: 3rem;
                                display: block;
                                float: right;
                                cursor: pointer;
                                width: 3rem;
                                background-size: 2.6rem;
                                background-repeat: no-repeat;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='#{color(base, White)}' viewBox='0 0 24 24' stroke='#{color(alert, Warning)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z' /%3E%3C/svg%3E");
                            }

                            &:checked ~ label, & + label:hover ~ label, & + label:hover {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='#{color(alert, Warning)}' viewBox='0 0 24 24' stroke='#{color(alert, Warning)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z' /%3E%3C/svg%3E");
                            }
                        }
                    }
                }
            }
        }
    }
}

a[data-open] {
    cursor: pointer;
}

.reveal {
    display: none;
    .modal__wrapper {
        overflow-y: auto;
        position: relative;
        .modal-content {
            padding: 20px;
        }
        .close-button {
            position: absolute;
            top: 10px;
            right: 20px;
            cursor: pointer;
            display: block;
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            span {
                display: none;
            }
        }
    }
}
