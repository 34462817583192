.tweakwise-recommendation {
    margin: 4rem auto 4rem;
    @include breakpoint(medium down) {
        margin: 4rem -2rem;
        padding: 0;
    }

    h3 {
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 3rem;
        margin: 6rem auto 3rem;
        text-align: center;
        color: color(base, PrimaryPink);
        @include breakpoint(small only) {
            font-size: 2.1rem;
            line-height: 2.6rem;
            margin: 0 0 1.6rem;
        }
    }
    .tw-recommendation-container {
        margin: 0 0 2rem;
    }
    .blaze-slider {
        position: relative;
        @include absolute-button();
        li {
            list-style-type: none;
            height: 100%;
            .product__tile {
                display: flex;
                flex-direction: column;
                height: 100%;
                .price__cta {
                    margin-top: auto;
                }
                .promo_time--container {
                    span:first-of-type {
                        font-size: 1.2rem;
                        padding: .3rem;
                        @include breakpoint(small only) {
                            font-size: .9rem;
                        }
                    }
                    span:last-of-type {
                        font-size: 1.1rem;
                        padding: .3rem;
                        @include breakpoint(small only) {
                            font-size: .9rem;
                        }
                    }
                }
            }
        }
    }

    .slider__container {
        position: relative;
        @include absolute-button();
    }
    .swiper-container {
        .swiper-slide {
            width: 50%;
            height: auto;
            @include breakpoint(medium) {
                width: 25%;
            }
            li {
                list-style-type: none;
                height: 100%;
                a {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .promo_time--container {
                        span:first-of-type {
                            font-size: 1.2rem;
                            padding: .3rem;
                            @include breakpoint(small only) {
                                font-size: .9rem;
                            }
                        }
                        span:last-of-type {
                            font-size: 1.1rem;
                            padding: .3rem;
                            @include breakpoint(small only) {
                                font-size: .9rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-builder__wrapper {
    .tweakwise-recommendation {
        margin: 4rem auto;
        @include breakpoint(small only) {
            margin: 4rem 1rem;
        }
    }
}
