.catalog__category-filter {
    @include breakpoint(small only) {
        .modal__header {
            .close-button {
                width: 20px;
                height: 50px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(gray, Gray-400)}'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                right: 0 !important;
                background-size: 20px;
                background-repeat: no-repeat;
                background-position: center;
                span {
                    display: block;
                    width: 50px;
                    height: 50px;
                }
                &._back {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(gray, Gray-300)}'%3E%3Cpath fill-rule='evenodd' d='M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                }
            }
        }
        .modal {
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
        }
        .modal__content {
            height: 100%;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            @include breakpoint(medium up) {
                padding: 0 10px;
            }
        }
        .modal__footer {
            padding: 10px;
            border-top: 0.2rem dotted color(gray, Gray-200);
            ._button {
                height: 40px;
                width: 100%;
                line-height: 40px;
                font-size: 13px;
                padding: 0;
                span {
                    color: color(base, White);
                    font-weight: 500;
                    display: block;
                    padding: 0 2rem;
                }
            }
        }
    }

    .modal__content {
        .title {
            font-size: 3.2rem;
            line-height: 4.6rem;
            font-weight: 500;
            margin: 0 0 .5rem;
            @include breakpoint(small only) {
                color: color(gray, Gray-300);
                display: flex;
                font-size: 1.4rem;
                line-height: 4.5rem;
            }
        }
        a {
            color: color(base, PrimaryBlue);
            text-decoration: underline;
            font-size: 1.2rem;
            display: inline-block;
            @include breakpoint(small only) {
                padding: 1.5rem;
                font-size: 1.4rem;
            }
        }
    }

    dl {
        @include breakpoint(medium up) {
            margin: 0 0 20px;
        }
        dt {
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            color: color(base, PrimaryPink);
            line-height: 2rem;
            padding: .5rem 2rem .5rem 0;
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;
            text-transform: uppercase;
            @include breakpoint(small only) {
                text-transform: none;
            }
            > span {
                display: flex;
                gap: .8rem;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                .tooltip {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{color(base, PrimaryPink)}' class='w-6 h-6'%3E%3Cpath fill-rule='evenodd' d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 2.1rem;
                    background-position: center;
                    margin-right: .5rem;
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    flex-shrink: 0;
                }
            }

            &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, PrimaryBlue)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right center;
                position: absolute;
                width: 16px;
                height: 16px;
                top: 50%;
                right: 0;
                background-size: 16px;
                transform: translateY(-50%);
                @include breakpoint(small only) {
                    right: 1.5rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-400)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
                }
            }
            @include breakpoint(small only) {
                padding: 0 15px;
                line-height: 44px;
                border-top: none;
                border-bottom: 1px solid color(gray, Gray-200);
                font-size: 14px;
                font-weight: 500;
            }
        }
        ._visible {
            @include transition;
            &::after {
                content: "";
                transform: translateY(-50%);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, PrimaryBlue)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5 15l7-7 7 7' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right center;
                position: absolute;
                width: 16px;
                height: 16px;
                top: 50%;
                right: 0;
                background-size: 16px;
                @include breakpoint(small only) {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-400)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5 15l7-7 7 7' /%3E%3C/svg%3E");
                    right: 1.5rem;
                }
            }
        }

        dd {
            ol {
                li {
                    @include breakpoint(small only) {
                        padding: 0 15px;
                        border-bottom: 1px solid color(gray, Gray-200);
                    }
                    a {
                        display: block !important;
                        text-decoration: none !important;
                        &:hover {
                            font-weight: 600;
                        }
                    }
                    label, a > span {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        line-height: 28px;
                        cursor: pointer;
                        color: color(base, DefaultFontColor);
                        @include breakpoint(small only) {
                            line-height: 49px;
                        }
                        @include breakpoint(between-medium-small only) {
                            font-size: 12px;
                        }
                        @include breakpoint(medium only) {
                            font-size: 12px;
                        }
                        &:hover {
                            ._checkbox {
                                border-color: color(gray, Gray-200);
                            }
                        }
                        .--title {
                            overflow: hidden;
                            white-space: nowrap;
                            flex: 1;
                            text-overflow: ellipsis;
                            font-size: 14px;
                        }
                        .--checkbox {
                            border: 1px solid color(gray, Gray-200);
                            display: block;
                            width: 14px;
                            height: 14px;
                            border-radius: 2px;
                            margin-right: 8px;
                        }
                        .--count {
                            margin-left: .3rem;
                            color: color(gray, Gray-300);
                            font-size: 11px;
                        }
                        &.--active .--checkbox {
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='7px' viewBox='0 0 10 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolygon id='Fill-1' stroke='%23231F20' fill='%23FFFFFF' points='3.54581617 5.53783002 1.6370223 3.34424853 1 4.0737009 3.54581617 7 9 0.732064558 8.36525075 0'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
                &._swatch {
                    display: flex;
                    flex-wrap: wrap;
                    @include breakpoint(small only) {
                        padding: 20px 0;
                        margin: 0;
                    }
                    li {
                        width: 25%;
                        @include breakpoint(small only) {
                            border-bottom: 0;
                        }
                        label {
                            display: block;
                            text-align: center;
                            padding: 3px 0;
                            ._color-container {
                                display: block;
                                text-align: center;
                                ._color {
                                    margin: 0 auto;
                                    display: block;
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    border: 1px solid transparent;
                                    &._white, &:hover {
                                        border-color: color(gray, Gray-200);
                                    }
                                }
                            }
                            ._title {
                                display: block;
                                font-size: 12px;
                            }
                            &._active ._color {
                                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='11px' viewBox='0 0 15 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='5.13132462 8.81921546 2.04945992 5.73735077 1 6.77942013 5.13132462 10.9107447 14 2.04206936 12.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23FFFFFF' fill='%23FFFFFF' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: center;
                                &._white {
                                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='11px' viewBox='0 0 15 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='5.13132462 8.81921546 2.04945992 5.73735077 1 6.77942013 5.13132462 10.9107447 14 2.04206936 12.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='White'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='#{color(base, Black)}' fill='#{color(base, Black)}' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                                }
                                &:before {
                                    content: '';
                                    display: block;
                                    width: calc(100% + 10px);
                                    height: calc(100% + 10px);
                                    margin: -5px 0 0 -5px;
                                    border-radius: 50%;
                                    border: 2px solid color(base, PrimaryBlue);
                                }
                            }
                        }
                    }
                }
                &._columns {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        width: 50%;
                    }
                }
            }
            .filter__actions {
                @include breakpoint(small only) {
                    padding: 10px 15px;
                }
                a {
                    font-size: 12px;
                    color: color(base, PrimaryPink);
                    font-weight: 500;
                }
            }
        }
        &.catalog__category-sub-categories {
            margin: 0 0 20px;
            dt {
                font-size: 16px;
                line-height: 19px;
            }
            dd {
                ol {
                    background: color(base, TertiaryBlue);
                    padding: 20px;
                    li {
                        a, span {
                            position: relative;
                            color: color(base, DefaultFontColor);
                            text-decoration: none;
                            font-weight: 400;
                            font-size: 1.4rem;
                            margin: 0;
                            &:hover, &._active {
                                font-weight: 500;
                                text-decoration: none;
                                &:before {
                                    content: '';
                                    display: inline-block;
                                    position: absolute;
                                    border-left: 0.2rem solid color(base, PrimaryPink);
                                    width: 0.2rem;
                                    height: 2.4rem;
                                    left: -2rem;
                                }
                            }
                        }
                        &._show-more {
                            a, span {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
        &._visible {
            dd {
                @include breakpoint(medium up) {
                    display: block;
                }
            }
        }
    }
    .price__filter {
        @include breakpoint(small only) {
            padding: 0 1.5rem;
        }
        .custom__controls {
            display: flex;
            justify-content: space-between;
            margin: 15px 0 10px;
            input[type=number] {
                width: 33.34%;
                @include breakpoint(medium only) {
                    padding: 0 10px;
                }
            }
        }
        ._error {
            font-size: 1.3rem;
            color: color(base, PrimaryPink);
            text-align: center;
        }
        .vue-slider {
            display: flex;
            justify-content: center;
            .vue-slider-rail {
                width: 90%;
                @include breakpoint(medium only) {
                    width: 80%;
                }
                @include breakpoint(between-medium-small only) {
                    width: 80%;
                }
                .vue-slider-process {
                    background: color(gray, Gray-200);
                }
                .vue-slider-dot {
                    z-index: 1;
                }
                .vue-slider-dot-handle {
                    width: 22px;
                    height: 22px;
                    box-shadow: none;
                    border-radius: 50%;
                    border: 2px solid color(gray, Gray-200);
                }
            }
        }
    }
}

.catalog__category-filter-placeholder {
    span {
        display: block;
        background: color(base, Black);
        height: 200px;
        margin: 0 0 20px;
    }
}
