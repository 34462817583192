.customer-account {
    padding: 3rem 0;
    @include breakpoint(small only) {
        padding: 2rem 0 6rem;
    }
    .messages {
        margin: 0 0 1rem;
    }
    .margin__bottom {
        margin-bottom: 2rem;
        align-items: center;
        h1 {
            margin: 0;
        }
    }
    h2, h3 {
        font-size: 2rem;
        margin: 0 0 1.5rem;
        font-weight: 500;
        color: color(base, Black);
    }
    @include breakpoint(small only) {
        h1 {
            margin: 0 0 2rem;
        }
    }
    h3 {
        font-size: 1.6rem;
        text-transform: initial;
    }
    h1 {
        margin: 0 0 1.5rem;
        font-size: 2.4rem;
        font-weight: 600;
        color: color(base, Black);
    }
    .breadcrumbs {
        display: none;
    }
    ._account-title {
        font-weight: 600;
        font-size: 2.4rem;
    }
    .customer-account_box {
        padding: 2rem;
        border-radius: 0.5rem;
        margin-bottom: 1.5rem;
        background: color(base, TertiaryBlue);
        &._dashboard {
            background: none;
            padding: 2rem 0;
            ._button {
                display: initial;
                width: auto;
            }
        }
        &._forget-container {
            margin: 0 0 4.5rem;
        }
        .field__container {
            label {
                font-weight: 500;
            }
        }
        ._button {
            display: block;
            width: 100%;
        }
        input {
            border: 1px solid color(base, SecondaryBlue);
        }
        ._forget-text {
            margin: 0 0 1rem;
        }
    }
    .customer__account-navigation_toggle {
        @include default-button;
        background-repeat: no-repeat;
        background-position: left .8rem center;
        background-size: 1.6rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-menu-2' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cline x1='4' y1='6' x2='20' y2='6' /%3E%3Cline x1='4' y1='12' x2='20' y2='12' /%3E%3Cline x1='4' y1='18' x2='20' y2='18' /%3E%3C/svg%3E");
        margin: 0 0 2rem;
        &._secondary {
            padding-left: 3rem;
        }
    }
    .customer__account-navigation {
        @include breakpoint(medium) {
            background: color(base, TertiaryBlue);
            padding: 0 0 1.5rem;
            max-width: 80%;
            border-radius: 0.5rem;
        }
        @include breakpoint(small only) {
            nav {
                height: auto;
                display: block;
                ul li {
                    a {
                        @include head-font;
                        display: block;
                        color: color(base, DefaultFontColor);
                        letter-spacing: .1rem;
                        padding: 0 0.9375em;
                        font-size: 1.4rem;
                        text-transform: none;
                        line-height: 4.5rem;
                    }
                }
            }
        }
        h3 {
            margin: 0 0 1rem;
            font-weight: 500;
            color: color(base, Black);
            font-size: 2rem;
            padding: 2rem 1.5rem;
        }
        ul li {
            @include breakpoint(medium) {
                a {
                    font-size: 1.5rem;
                    display: block;
                    line-height: 3.2rem;
                    color: color(base, DefaultFontColor);
                    padding: 0 2rem;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &._active {
                a {
                    font-weight: 500;
                    pointer-events: none;
                    border-left: 2px solid color(base, PrimaryPink);
                    color: color(base, Black);
                }
            }
        }
    }
    ._date {
        margin-left: auto;
        line-height: 3.4rem;
        font-size: 1.4rem;
        margin-right: 1rem;
        @include breakpoint(small only) {
            line-height: normal;
            margin: 20px 0 0 auto;
            display: block;
        }
    }
    ._status {
        line-height: 3.4rem;
        font-size: 1.4rem;
        margin-right: 1rem;
        font-weight: 600;
        margin-bottom: 1rem;
        display: inline-block;
        a {
            font-weight: 600;
            font-size: 1.4rem;
            color: color(base, PrimaryPink);
        }
    }
    ._new-address {
        margin-left: auto;
        margin-top: 2rem;
    }
    .customer__block {
        margin: 0 0 2rem;
        .--item {
            span {
                display: flex;
                align-items: center;
                gap: 1rem;
                height: 100%;
                @include breakpoint(small only) {
                    overflow: hidden;
                    white-space: normal;
                    text-overflow: ellipsis;
                    max-width: 20rem;
                    font-size: 1.2rem;
                    max-height: fit-content;
                    flex-direction: column;
                    align-items: flex-start;
                    background: inherit;
                }
            }
        }
        .a-center {
            text-align: center;
        }
        .a-right {
            text-align: right;
        }
        span {
            font-size: 1.4rem;
            background: color(base, TertiaryBlue);
            padding: 1.5rem;
            margin: 0 0 1.5rem;
            border-radius: 0.5rem;
            display: block;
            height: 10.2rem;
            max-height: 10.2rem;
        }
        table {
            width: 100%;
            margin: 0 0 2rem;
            font-size: 1.4rem;
            tr {
                &:nth-child(even) {
                    background: #FAFAFA;
                }
                th {
                    text-align: left;
                    font-weight: 500;
                }
                th, td {
                    padding: .4rem .2rem;
                    vertical-align: middle;
                    strong {
                        font-weight: normal;
                    }
                    ul li {
                        font-size: 1.4rem;
                        strong {
                            font-weight: normal;
                        }
                    }
                    a:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        ._newsletter {
            a {
                text-decoration: underline;
            }
        }
    }
    .order__totals {
        ul {
            margin-left: auto;
            li {
                display: flex;
                span, strong {
                    display: block;
                    flex: 1;
                    text-align: right;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    &:first-child {
                        width: 14rem;
                    }
                }
                strong {
                    font-size: 1.6rem;
                    font-weight: normal;
                }
            }
        }
    }
    ._login-title, ._forget-title, ._register-title {
        color: color(base, Black);
        font-weight: 600;
        font-size: 2.4rem;
        margin: 1rem 0 0.5rem;
    }

    ._new-title {
        color: color(base, Black);
        font-weight: 500;
        margin: 1rem 0 0;
    }

    .customer__account_block {
        ._button {
            display: block;
            width: 100%;
            text-align: center;
        }
        &._login, &._register {
            padding: 2rem;
            border-radius: 0.5rem;
            margin-bottom: 1.5rem;
            background: color(base, TertiaryBlue);
            @include breakpoint(small only) {
                margin-bottom: 4rem;
            }
            input {
                border: 1px solid color(base, SecondaryBlue);
            }

            ._forget {
                font-size: 1.3rem;
                font-weight: normal;
                text-decoration: underline;
                color: color(base, Black);
                text-align: center;
                display: block;
                margin: 1rem 0;
            }
            .field__container {
                label {
                    font-weight: 500;
                }
            }
        }
        &._register {
            .field__container {
                &._flex {
                    display: flex;
                    margin: 0;
                    gap: 1rem;
                    .field__container {
                        &:nth-of-type(1) {
                            width: 40%;
                        }
                        &:nth-of-type(2) {
                            width: 60%;
                        }
                    }
                }
                &.flex {
                    display: flex;
                }
            }
        }
        &._new-account {
            margin: 0 0 6rem;
        }
        ._usp {
            margin: 0.5rem 0 2rem;
            line-height: 2rem;
            font-size: 1.4rem;
            h3 {
                margin: 0 0 1rem;
            }
            p {
                line-height: 2rem;
                font-size: 1.4rem;
            }
            li {
                line-height: 3rem;
                color: color(base, Black);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-check' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(alert, Success)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M5 12l5 5l10 -10' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 2.5rem;
                font-size: 1.3rem;
                @include breakpoint(small only) {
                    font-size: 1.2rem;
                }
            }
        }
    }
    ._intro {
        margin: 0 0 2rem;
        color: color(base, Black);
    }
    ._header {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        a {
            margin-left: auto;
            font-size: 1.3rem;
            text-decoration: underline;
        }
        h2, h3 {
            margin: 0;
        }
    }
    .address__block {
        background: color(base, TertiaryBlue);
        padding: 1rem 1.2rem;
        margin: 0 0 1.5rem;
        border-radius: 0.5rem;
        > span {
            font-size: 1.4rem;
            line-height: 2.4rem;
        }
        address {
            font-size: 1.4rem;
            line-height: 2.4rem;
            font-style: normal;
        }
        > a {
            display: inline-block;
            font-size: 1.3rem;
            margin-top: 1rem;
            text-decoration: underline;
        }
        ._actions {
            display: flex;
            margin: 1rem 0 0;
            a {
                display: block;
                font-size: 1.3rem;
                margin-right: 1rem;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .customer__wishlist{
        ._new-title{
            margin-bottom: 1.6rem;
        }
    }
}
