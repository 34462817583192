@use 'sass:math';

header {
    border-top: .2rem solid color(base, PrimaryPink);
    .sticky {
        background: color(base, White);
        &.is-stuck {
            box-shadow: 0 .7rem .9rem rgb(0 0 0 / 15%);
            z-index: 8;
            .top__container {
                display: none;
            }
        }
    }
    .top__banner {
        background: color(base, Black);
        width: 100%;
        padding: 1.0rem 0;
        color: color(base, White);
        position: fixed;
        z-index: 11;
        top: 0;
        border-top: 0.2rem solid #fc1057;
        .--close {
            position: absolute;
            right: 2rem;
            top: 6px;
            font-size: 2.5rem;
            cursor: pointer;
        }
        a {
            font-size: inherit;
            font-weight: inherit;
        }
    }
    .top__banner_placeholder{
        height: 4.5rem;
    }
    .top__container {
        display: flex;
        align-items: center;
        border-bottom: .2rem solid color(base, TertiaryBlue);
        padding: .5rem 0;
        @include breakpoint(medium) {
            padding: 0.8rem 0;
        }
        .trustpilot-widget {
            margin-left: auto;
            width: 100%;
            max-width: 450px;
        }

        .top__container-navigation {
            display: flex;
            align-items: center;
            gap: 3rem;
            @include breakpoint(between-medium-small only) {
                gap: 1.5rem;
            }
            @include breakpoint(small only) {
                display: none;
            }

            li {
                color: color(gray, Gray-400);
                display: block;
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 2rem;
                padding-left: 2.3rem;
                font-size: 1.4rem;
                background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGNsYXNzPSdoLTUgdy01JyB2aWV3Qm94PScwIDAgMjAgMjAnIGZpbGw9JyMwMGI1MDAnPjxwYXRoIGZpbGwtcnVsZT0nZXZlbm9kZCcgZD0nTTE2LjcwNyA1LjI5M2ExIDEgMCAwMTAgMS40MTRsLTggOGExIDEgMCAwMS0xLjQxNCAwbC00LTRhMSAxIDAgMDExLjQxNC0xLjQxNEw4IDEyLjU4Nmw3LjI5My03LjI5M2ExIDEgMCAwMTEuNDE0IDB6JyBjbGlwLXJ1bGU9J2V2ZW5vZGQnLz48L3N2Zz4=);
                @include breakpoint(medium only) {
                    &:nth-of-type(1) {
                        display: none;
                    }
                    &:nth-of-type(3) {
                        display: none;
                    }
                }
                @include breakpoint(between-medium-small only) {
                    font-size: 1.2rem;
                }
                @include breakpoint(small only) {
                    &:nth-of-type(1) {
                        display: none;
                    }
                    &:nth-of-type(3) {
                        display: none;
                    }
                }
            }
        }
    }
    .header__container {
        align-items: center;
        padding: 1rem 0;
        @include breakpoint(small only) {
            padding: 1rem 0 0;
        }
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3rem;
            color: color(base, PrimaryBlue);
            margin-right: 1.5rem;
            background-image: url("data:image/svg+xml, %3Csvg width='30' height='24' xmlns='http://www.w3.org/2000/svg' data-toggle='offCanvas'%3E%3Cpath d='M1.5 13.5a1.5 1.5 0 0 1 0-3h27a1.5 1.5 0 0 1 0 3h-27ZM1.5 3a1.5 1.5 0 0 1 0-3h27a1.5 1.5 0 0 1 0 3h-27Zm0 21a1.5 1.5 0 0 1 0-3h27a1.5 1.5 0 0 1 0 3h-27Z' fill='#{color(base, PrimaryBlue)}' fill-rule='evenodd'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 2rem;
            width: 3rem;
        }
        .header__logo {
            display: inline-flex;
            img {
                max-width: 20rem;
                height: auto;
                @include breakpoint(small only) {
                    max-width: 10rem;
                }
            }
        }
        .header__navigation {
            margin: 1rem 0;
            @include breakpoint(small only) {
                gap: 1rem;
                height: 4rem;
                margin: 0.5rem 0 1rem 1rem;
                width: 100%;
            }
            .header__cart-container {
                position: relative;
                ._dropdown {
                    position: absolute;
                    overflow: hidden;
                    z-index: 9;
                    background: color(base, White);
                    width: 350px;
                    right: 0;
                    top: 5rem;
                    border: 1px solid color(base, TertiaryBlue);
                    border-radius: .5rem;
                    @include breakpoint(small only) {
                        right: -3rem;
                        width: calc(100vw - 1rem);
                    }
                    ._added {
                        background: color(base, White);
                        border-bottom: 1px solid color(base, TertiaryBlue);
                        p {
                            color: color(alert, Success);
                            font-size: 13px;
                            text-align: center;
                            font-weight: 500;
                            margin: 0 auto;
                            line-height: 30px;
                            padding: 0 10px;
                        }
                    }
                    .cart__items {
                        font-size: 14px;
                        ul {
                            display: flex;
                            flex-direction: column;
                            gap: 0;
                            li {
                                border-bottom: 1px solid color(base, TertiaryBlue);
                                width: 100%;
                                a, div {
                                    padding: 5px 10px;
                                    display: flex;
                                    gap: .5rem;
                                    line-height: 6rem;
                                    margin: 0;
                                    height: 6rem;
                                    font-size: 1.3rem;
                                    color: color(base, DefaultFontColor);
                                    &:hover {
                                        background: color(base, TertiaryBlue);
                                        text-decoration: none;
                                        &._name {
                                            text-decoration: underline;
                                        }
                                    }
                                    strong {
                                        font-weight: 600;
                                        margin-right: 5px;
                                        line-height: 22px;
                                    }
                                    ._price {
                                        margin-left: auto;
                                        padding-left: 10px;
                                    }
                                    ._name {
                                        line-height: 1.8rem;
                                    }
                                    &.--bundle-item {
                                        cursor: default;
                                    }
                                }
                            }
                        }
                    }
                    .cart__totals {
                        padding: 1rem 1rem 1.5rem;
                        font-size: 13px;
                        ._totals {
                            display: flex;
                            align-items: flex-end;
                            flex-direction: column;
                            gap: 0.5rem;
                            li {
                                display: flex;
                                line-height: 24px;
                                font-weight: 500;
                                span {
                                    &:last-child {
                                        margin-left: 2rem;
                                    }
                                    &._free {
                                        color: color(alert, Success);
                                    }
                                }
                                &._grand {
                                    span {
                                        font-weight: 700;
                                        font-size: 1.6rem;
                                        &:last-of-type {
                                            margin-left: 2rem;
                                        }
                                    }
                                }
                            }
                        }
                        ._actions {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-end;
                            margin: 10px 0 0;
                            a {
                                height: 32px;
                                line-height: 32px;
                                &._continue {
                                    font-size: 13px;
                                    margin-right: 20px;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                            ._button.--cta {
                                color: color(base, White);
                            }
                        }
                    }
                }
            }
            ul {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 1.5rem;
                @include breakpoint(small only) {
                    gap: 0.5rem;
                }
                .header__navigation-item {
                    @include breakpoint(small only) {
                        display: none;
                    }
                }
                a, button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 3rem;
                    color: color(base, PrimaryBlue);
                    &.--cart {
                        color: color(base, PrimaryPink);
                        gap: 1rem;
                        position: relative;
                        ._amount {
                            color: color(base, White);
                            font-weight: 500;
                            position: absolute;
                            left: 37%;
                            top: 38%;
                            width: 1.5rem;
                            text-align: center;
                            @include breakpoint(medium) {
                                color: color(base, White);
                                font-weight: 500;
                                position: absolute;
                                left: 4%;
                                top: 22%;
                                font-size: 1.1rem;
                                width: 1.5rem;
                                text-align: center;
                            }
                        }
                        .--amount {
                            color: color(gray, Gray-400);
                            font-size: 1.2rem;
                            position: absolute;
                            bottom: 2.2rem;
                            right: 0;
                            @include breakpoint(medium only) {
                                display: none;
                            }
                            @include breakpoint(between-medium-small only) {
                                display: none;
                            }
                        }
                    }
                }
                ._wishlist {
                    background-image: url("data:image/svg+xml,%3Csvg width='34px' height='30px' viewBox='0 0 37 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='20951337511543238895' transform='translate(1.548709, 0.998174)' stroke='%231589c9' stroke-width='3'%3E%3Cpath d='M31.7433225,2.65221759 C30.045976,0.954074102 27.7434017,7.30737369e-16 25.3424276,7.30737369e-16 C22.9414535,7.30737369e-16 20.6388792,0.954074102 18.9415327,2.65221759 L17.19733,4.39642032 L15.4531272,2.65221759 C11.9180106,-0.882898968 6.1864541,-0.882898926 2.65133749,2.65221769 C-0.883779125,6.1873343 -0.883779168,11.9188908 2.65133739,15.4540074 L4.39554012,17.1982102 L17.19733,30 L29.9991198,17.1982102 L31.7433225,15.4540074 C33.441466,13.7566609 34.3955401,11.4540866 34.3955401,9.05311251 C34.3955401,6.65213843 33.441466,4.34956412 31.7433225,2.65221759 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-position: top;
                    background-size: 3rem;
                    width: 5.5rem;
                    @include header-icons;
                    position: relative;
                    @include breakpoint(small only) {
                        background-size: 2rem;
                        background-position: center;
                    }
                    ._amount {
                        width: 2rem;
                        height: 2rem;
                        background: color(base, PrimaryPink);
                        position: absolute;
                        color: color(base, White);
                        font-weight: 600;
                        text-align: center;
                        border-radius: 50%;
                        line-height: 2rem;
                        top: -0.5rem;
                        right: 0.5rem;
                        font-size: 1.2rem;
                    }
                }
                ._stores {
                    background-image: url("data:image/svg+xml, %3Csvg width='25px' height='30' viewBox='0 0 25 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linejoin='round'%3E%3Cg id='17094183761571183081' transform='translate(1.000000, 1.000000)' stroke='%231589C9' stroke-width='3'%3E%3Cpath d='M22.5,11.25 C22.5,21.25 11.25,30 11.25,30 C11.25,30 0,21.25 0,11.25 C0,5 5,0 11.25,0 C17.5,0 22.5,5 22.5,11.25 Z' id='Path'%3E%3C/path%3E%3Ccircle id='Oval' cx='11.25' cy='11.25' r='3.75'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-position: top;
                    background-size: 2.3rem;
                    @include header-icons;
                    @include breakpoint(small only) {
                        background-position: center;
                        background-size: 1.6rem;
                    }
                }
                ._account {
                    background-image: url("data:image/svg+xml, %3Csvg width='28' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.575 2.715a5.43 5.43 0 1 0 0 10.86 5.43 5.43 0 0 0 0-10.86Zm0-2.715a8.145 8.145 0 1 1 0 16.29 8.145 8.145 0 0 1 0-16.29ZM2.715 28.642a1.358 1.358 0 0 1-2.715 0v-2.849a6.788 6.788 0 0 1 6.788-6.788h13.575a6.788 6.788 0 0 1 6.788 6.788v2.85a1.358 1.358 0 0 1-2.716 0v-2.85a4.073 4.073 0 0 0-4.072-4.073H6.788a4.073 4.073 0 0 0-4.073 4.073v2.85Z' fill='#{color(base, PrimaryBlue)}' fill-rule='evenodd'/%3E%3C/svg%3E");
                    background-position: top;
                    background-size: 2.5rem;
                    @include header-icons;
                }
                ._cart {
                    background-image: url("data:image/svg+xml, %3Csvg width='30' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m29.155 2.791-4.127 1.765-3.46 16.287a1.956 1.956 0 0 1-.11.3 2.75 2.75 0 0 1-.88 1.588 2.63 2.63 0 0 1-1.66.664H6.04c-1.318 0-2.254-.998-2.686-2.552L.004 10.447v-.3A2.839 2.839 0 0 1 .71 8.132a2.703 2.703 0 0 1 1.89-.912h18.403c.103-.006.205-.006.307 0l.732-3.828c.085-.424.355-.784.732-.976.09-.063.189-.114.292-.15l5.05-2.162a1.33 1.33 0 0 1 1.06.013c.338.15.602.436.733.79.263.733-.066 1.55-.755 1.877v.007ZM8.213 24.897c1.01-.03 1.938.571 2.35 1.522.409.95.214 2.062-.491 2.806a2.44 2.44 0 0 1-2.72.587 2.501 2.501 0 0 1-1.129-.938 2.599 2.599 0 0 1-.425-1.425 2.546 2.546 0 0 1 .692-1.79 2.417 2.417 0 0 1 1.723-.762Zm7.874 0c1.01-.03 1.938.571 2.35 1.522a2.6 2.6 0 0 1-.492 2.806 2.44 2.44 0 0 1-2.72.587 2.502 2.502 0 0 1-1.128-.938 2.599 2.599 0 0 1-.426-1.425c-.01-.67.242-1.317.698-1.795a2.418 2.418 0 0 1 1.732-.757h-.014Z' fill='#{color(base, PrimaryPink)}' fill-rule='nonzero'/%3E%3C/svg%3E");
                    background-position: left top;
                    background-size: 2.7rem;
                    width: 7.5rem;
                    @include header-icons;
                    @include breakpoint(small only) {
                        background-size: 2.1rem;
                        background-position: center;
                    }
                }
            }
        }
        .search__component {
            padding: .2rem .5rem;
            position: relative;
            @include breakpoint(small only) {
                margin: 0 -#{math.div($grid__gutter, 2)};
                background: #e7f3fa;
            }
            @include breakpoint(medium) {
                margin: 0 clamp(1rem, 5vw, 10rem);
                padding: 0;
            }
            form {
                @include breakpoint(small only) {
                    display: flex;
                }
                input {
                    @include breakpoint(small only) {
                        background-color: transparent;
                        border-radius: unset;
                        border: unset;
                        flex: 1;
                    }
                    @include breakpoint(medium) {
                        border: .2rem solid color(base, PrimaryBlue);
                        height: 4.8rem;
                        line-height: 4.8rem;
                        border-radius: 2.3rem;
                    }
                }
                button {
                    width: 4rem;
                    height: 4rem;
                    margin-right: 1rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='%23fc1057' fill-rule='evenodd' d='m10.094 16.32-5.387 5.387a1 1 0 0 1-1.414-1.414l5.387-5.387a8 8 0 1 1 1.414 1.414zM15 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center;
                    @include breakpoint(medium) {
                        position: absolute;
                        inset: 0 0 0 auto;
                        line-height: 4.6rem;
                        height: 4.6rem;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='#{color(base, PrimaryPink)}' fill-rule='evenodd' d='m10.094 16.32-5.387 5.387a1 1 0 0 1-1.414-1.414l5.387-5.387a8 8 0 1 1 1.414 1.414zM15 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'/%3E%3C/svg%3E");
                        &:hover {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='%231589c9' fill-rule='evenodd' d='m10.094 16.32-5.387 5.387a1 1 0 0 1-1.414-1.414l5.387-5.387a8 8 0 1 1 1.414 1.414zM15 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'/%3E%3C/svg%3E");
                        }
                    }
                    @include breakpoint(small only) {
                        height: 5rem;
                    }
                }
            }
        }
    }
}
