.off-canvas {
    &.--wide {
        width: 90%;
    }
    &.--medium{
        width:40rem;
        @include breakpoint(small only) {
            width: 90%;
        }
    }
    &.bundle__item{
        .off-canvas__container{
            display: flex;
            flex-direction: column;
            height: 100%;
            .bundle-items{
                overflow: auto;
                flex-shrink:1;
            }
        }
    }
    &.position-left {
        overflow: hidden;
    }
    &#offCanvas-filter {
        @include breakpoint(large up) {
            width: 100%;
            overflow: visible;
        }
    }
    .modal__header {
        position: relative;
        display: flex;
        line-height: 4.5rem;
        font-size: 1.4rem;
        border-bottom: .2rem dotted color(gray, Gray-200);
        color: color(gray, Gray-300);
        padding: 0 1.5rem;
        button {
            position: static;
            margin-left: auto;
            line-height: 4.5rem;
            font-size: 3.8rem;
            color: color(base, PrimaryPink);
        }
    }
    .drilldown {
        border-bottom: .1rem solid Green;
        ul > li > a {
            display:flex;
            border: none;
            line-height: 4.5rem;
            font-size: 1.4rem;
            font-weight: normal;
            color: color(base, DefaultFontColor);
        }
        border-bottom: none;
    }
}
